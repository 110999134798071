import Svg404 from "./Svg404";
import SvgAnchorRight from "./SvgAnchorRight";
import SvgCar from "./SvgCar";
import SvgCardView from "./SvgCardView";
import SvgCheckCircle from "./SvgCheckCircle";
import SvgCheckCircleEx from "./SvgCheckCircleEx";
import SvgCheckMark from "./SvgCheckMark";
import SvgCrossCircle from "./SvgCrossCircle";
import SvgCrossCircleMobile from "./SvgCrossCircleMobile";
import SvgCrossMark from "./SvgCrossMark";
import SvgCustomerService from "./SvgCustomerService";
import SvgExternalJob from "./SvgExternalJob";
import SvgForkKnife from "./SvgForkKnife";
import SvgGridView from "./SvgGridView";
import SvgHeartButton from "./SvgHeartButton";
import SvgHeartButtonActive from "./SvgHeartButtonActive";
import SvgHeartLiked from "./SvgHeartLiked";
import SvgHospitality from "./SvgHospitality";
import SvgJob from "./SvgJob";
import SvgOfficeAdmin from "./SvgOfficeAdmin";
import SvgPDF from "./SvgPDF";
import SvgLabour from "./SvgLabour";
import SvgLocationPick from "./SvgLocationPick";
import SvgLocationPin from "./SvgLocationPin";
import SvgLocation from "./SvgLocation";
import SvgNotify from "./SvgNotify";
import SvgPersonWalk from "./SvgPersonWalk";
import SvgPencilEdit from "./SvgPencilEdit";
import SvgProfile from "./SvgProfile";
import SvgRetail from "./SvgRetail";
import SvgRightArrow from "./SvgRightArrow";
import SvgSaleMarketing from "./SvgSaleMarketing";
import SvgSwobLogo from "./SvgSwobLogo";
import SvgSwobLogoTMMark from './SvgSwobLogoTMMark'
import SvgHealthCare from './SvgLocation';
import SvgBriefcase from "./SvgBriefcase";


const Icons = {
  Svg404,
  SvgAnchorRight,
  SvgCar,
  SvgCardView,
  SvgCheckCircle,
  SvgCheckCircleEx,
  SvgCheckMark,
  SvgCrossCircle,
  SvgCrossCircleMobile,
  SvgCrossMark,
  SvgCustomerService,
  SvgExternalJob,
  SvgForkKnife,
  SvgGridView,
  SvgHeartButton,
  SvgHeartButtonActive,
  SvgHeartLiked,
  SvgHospitality,
  SvgJob,
  SvgOfficeAdmin,
  SvgPDF,
  SvgLabour,
  SvgLocationPick,
  SvgLocationPin,
  SvgLocation,
  SvgNotify,
  SvgPersonWalk,
  SvgPencilEdit,
  SvgProfile,
  SvgRetail,
  SvgRightArrow,
  SvgSaleMarketing,
  SvgSwobLogo,
  SvgSwobLogoTMMark,
  SvgHealthCare,
  SvgBriefcase
};

export default Icons;
