import React from "react";
import ModalWrapper from "./ModalWrapper";

const ResetIgnoredJobModal = (props) => {
  const { onCrossClick = () => {}, onResetClick = () => {} } = props;

  return (
    <ModalWrapper {...props}>
      <div className="flex-col items-center justify-center w-full">
        <p className="px-12 py-1 text-xl font-semibold text-center">
          Are you sure you want to reset Ignored Jobs?
        </p>
        <p className="mx-auto text-sm text-center text-gray-500 py-7 md:w-1/2">
          When you reset ignored jobs, previous jobs that you swiped left on will
          reappear.
        </p>
        <div className="grid justify-between w-full grid-cols-2 gap-4 mb-2">
          <button
            className="py-3 text-base text-center text-white rounded bg-light-grey-blue-02"
            onClick={onCrossClick}>
            Cancel
          </button>
          <button
            className="py-3 text-base text-center text-white rounded bg-primary-blue"
            onClick={onResetClick}>
            Reset now
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ResetIgnoredJobModal;
