import React from "react";
import UpdateJobModalWrapper from "./UpdateJobModalWrapper";

const UpdateJobLocationModal = (props) => {
  return (
    <UpdateJobModalWrapper
      title="Where would you like to work?"
      childComponent="StepTwo"
      {...props}
    />
  );
};

export default UpdateJobLocationModal;
