import * as React from "react"

const SvgPersonWalk = (props) => (
  <svg
    width={17}
    height={26}
    fill="none"
    viewBox="0 0 17 26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m2.887 9.164 3.739-2.716c.42-.307.93-.465 1.45-.448a3.052 3.052 0 0 1 2.831 2.123c.217.68.416 1.14.595 1.38a5.824 5.824 0 0 0 4.665 2.33v2.334a8.15 8.15 0 0 1-6.303-2.972l-.813 4.614 2.405 2.019 2.593 7.126-2.193.798-2.38-6.538-3.955-3.32a2.333 2.333 0 0 1-.832-2.22l.594-3.367-.79.574-2.481 3.416-1.888-1.372L2.867 9.15l.02.014ZM9.75 5.417a2.333 2.333 0 1 1 0-4.667 2.333 2.333 0 0 1 0 4.667ZM6.283 20.794l-3.75 4.469-1.787-1.5 3.472-4.136.87-2.544 2.09 1.75-.895 1.962Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgPersonWalk
