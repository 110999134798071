import React from "react";
import SuitCaseIcon from '../../images/suitcase.png'

// import emptyLogo from "../../../assets/empty-logo.png";

const JobLogoAndAddress = (props) => {
  const {
    address,
    addressCity,
    employer,
    city,
    state,
    addressState,
    metadata,
    isExternalJob,
    logo,
    logoClassName = "w-12 h-12",
    addressClassName = "",
    className = "",
  } = props;

  return (
    <div className={`flex mb-3 gap-2 items-center ${className}`}>
      <div className="border-2 border-black  p-3 items-center justify-center">
        <img
          src={
            logo ? logo : SuitCaseIcon
          }
          className={`object-contain ${logo ? logoClassName : 'w-7 h-7'}`}
        />
      </div>
      <div className="flex-1 overflow-hidden">
        {employer?.name && (
          <p className={`pb-1 text-gray-700 truncate ${addressClassName}`}>
            {employer.name}
          </p>
        )}
        {isExternalJob && employer && (
          <p className={`pb-1 text-gray-700 truncate ${addressClassName}`}>{employer}</p>
        )}
        <p className={`text-gray-700 truncate ${addressClassName}`}>
          {address || `${city || addressCity || ""} ${state || addressState || ""}`}
        </p>
      </div>
    </div>
  );
};

export default JobLogoAndAddress;
