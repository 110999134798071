import * as React from "react";

const SvgLocation = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.000000 512.000000"
    width={25}
    height={25}
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M1194 5102 c-104 -36 -209 -123 -253 -211 -12 -22 -21 -49 -21 -60 0
-16 -25 -32 -114 -75 -192 -91 -285 -162 -361 -276 -79 -119 -89 -159 -89
-355 0 -150 3 -185 28 -295 51 -226 130 -449 250 -702 l46 -97 -25 -47 c-19
-35 -25 -62 -25 -106 0 -71 13 -103 81 -205 241 -360 548 -595 894 -687 l80
-21 5 -202 c4 -173 7 -205 22 -219 24 -24 75 -22 101 5 21 21 22 28 22 266 0
292 8 275 -135 304 -231 48 -414 149 -607 334 -162 156 -313 359 -313 422 0
40 35 75 76 75 35 0 61 -25 124 -120 141 -212 325 -382 513 -475 135 -67 262
-96 417 -96 175 0 294 28 445 104 164 82 361 267 498 467 75 109 101 129 150
116 36 -9 62 -59 49 -95 -10 -29 -112 -177 -175 -256 -131 -162 -290 -291
-469 -381 -95 -48 -162 -71 -278 -95 -68 -14 -100 -25 -112 -40 -17 -19 -18
-56 -18 -446 0 -348 3 -438 16 -501 80 -390 359 -686 744 -790 130 -35 351
-37 480 -5 344 87 616 333 726 657 52 151 54 186 54 795 l0 567 80 0 80 0 0
-566 c0 -472 -3 -582 -16 -661 -35 -209 -133 -417 -267 -569 -54 -62 -63 -82
-48 -119 16 -36 57 -56 92 -43 29 11 96 87 164 187 64 94 138 250 170 360 52
182 55 223 55 861 l0 590 53 22 c124 52 256 182 320 316 53 110 71 199 65 325
-9 178 -67 309 -193 436 -115 115 -243 176 -403 194 -86 9 -128 -4 -138 -43
-16 -65 29 -106 114 -106 96 0 234 -65 318 -148 282 -282 159 -753 -225 -866
-78 -23 -213 -21 -293 4 -166 51 -308 202 -349 370 -18 74 -16 203 5 271 36
122 123 231 241 303 80 49 99 80 74 124 -42 75 -147 36 -289 -107 -136 -137
-194 -276 -193 -466 0 -118 19 -200 70 -303 60 -122 196 -252 321 -306 l42
-19 0 -584 c0 -648 -2 -669 -65 -816 -110 -257 -295 -422 -562 -504 -78 -23
-104 -26 -233 -26 -129 0 -155 3 -233 26 -145 45 -255 107 -356 202 -110 103
-181 215 -233 365 l-32 95 -4 406 -3 406 84 21 c215 54 425 175 613 354 91 86
168 179 268 323 104 148 118 220 61 323 l-24 43 48 97 c102 205 208 504 253
712 25 120 36 370 19 453 -18 82 -71 183 -137 255 -69 76 -207 169 -324 217
l-87 37 -17 54 c-35 111 -196 241 -321 259 -107 17 -215 -44 -273 -153 -80
-150 -37 -341 94 -418 112 -66 327 -26 436 80 l36 35 95 -44 c192 -86 301
-190 341 -325 20 -68 21 -246 2 -355 -41 -230 -121 -467 -246 -727 l-52 -108
-44 0 c-91 0 -145 -38 -227 -160 -195 -289 -409 -457 -657 -515 -95 -22 -267
-19 -365 5 -245 62 -442 220 -644 518 -75 110 -119 141 -210 149 l-59 6 -45
96 c-190 404 -278 730 -264 980 4 83 10 109 36 163 54 115 163 207 327 278
l89 38 43 -40 c71 -65 188 -108 297 -108 105 0 182 50 238 154 18 34 22 57 22
136 0 84 -3 102 -28 152 -35 72 -104 133 -170 153 -65 19 -100 19 -163 -3z
m141 -152 c43 -27 64 -60 71 -114 9 -64 -2 -110 -37 -147 -28 -30 -32 -32 -92
-27 -120 10 -226 79 -212 137 18 72 140 167 219 170 10 1 33 -8 51 -19z m1292
-5 c58 -29 129 -101 139 -140 9 -38 -20 -74 -91 -110 -40 -20 -78 -31 -120
-33 -60 -4 -62 -3 -93 32 -53 60 -55 146 -7 216 45 65 92 75 172 35z"
      />
      <path
        d="M4049 3320 c-235 -39 -326 -334 -157 -507 75 -76 194 -106 303 -75
66 19 136 80 172 151 28 54 33 73 33 135 0 61 -5 83 -31 134 -34 70 -72 105
-144 138 -60 26 -115 34 -176 24z m137 -171 c40 -24 64 -74 64 -131 0 -133
-170 -194 -259 -93 -57 65 -51 160 14 212 58 46 118 50 181 12z"
      />
      <path
        d="M1735 1390 c-43 -17 -48 -34 -42 -148 29 -587 468 -1094 1049 -1212
203 -42 403 -37 602 15 156 41 354 134 429 203 37 35 37 83 1 111 -36 29 -51
27 -120 -15 -268 -164 -533 -222 -814 -180 -275 42 -535 185 -715 394 -169
196 -263 423 -284 688 -7 99 -10 109 -35 131 -29 25 -39 27 -71 13z"
      />
    </g>
  </svg>
);

export default SvgLocation;
