import * as React from "react"

const SvgForkKnife = (props) => (
  <svg
    width={21}
    height={24}
    fill="none"
    viewBox="0 0 21 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.502 17.937c0-1.636-.004-3.272.005-4.908 0-.21-.039-.275-.265-.279C2.385 12.726.926 11.71.256 9.98a3.901 3.901 0 0 1-.25-1.406C-.004 6.114 0 3.656.002 1.197c0-.489.2-.866.652-1.08.428-.201.836-.14 1.196.159.312.257.405.608.403 1.002-.006 1.73.002 3.459-.007 5.189-.001.226.041.303.284.293a23.662 23.662 0 0 1 1.688 0c.232.008.292-.051.29-.288-.01-1.699-.006-3.397-.006-5.095 0-.274.025-.54.192-.773C4.999.179 5.489 0 5.955.146c.43.135.792.65.796 1.164.006 1.01.003 2.02.003 3.032 0 .72.006 1.438-.004 2.156-.003.196.039.269.252.262a29.094 29.094 0 0 1 1.719 0c.231.008.292-.051.29-.287-.01-1.74-.003-3.48-.007-5.22 0-.5.17-.901.632-1.127.782-.382 1.612.19 1.618 1.12.009 1.26.003 2.52.003 3.782v3.344c0 1.942-.946 3.371-2.73 4.11-.477.199-.986.268-1.496.267-.247 0-.281.077-.281.298.007 3.21.004 6.418.005 9.628 0 .343-.069.645-.322.91-.334.347-.696.525-1.17.342-.485-.188-.757-.594-.758-1.145-.003-1.614 0-3.23 0-4.845h-.003Zm1.125-8.932c-1.02 0-2.04.006-3.06-.003-.196-.002-.22.05-.17.227.195.692.855 1.248 1.608 1.266 1.081.025 2.165.025 3.246 0 .744-.018 1.383-.554 1.597-1.239.066-.21.027-.258-.193-.256-1.009.012-2.02.005-3.028.005ZM20.63 12.19c0 3.48-.004 6.96.007 10.438.001.492-.177.859-.544 1.162-.55.454-1.51.084-1.675-.655a2.128 2.128 0 0 1-.036-.466c-.003-1.958-.005-3.917.004-5.875 0-.226-.043-.3-.285-.293-.624.02-1.25-.004-1.875.008-1.512.03-3.193-1.155-3.142-3.095.053-2-.031-4.001.1-5.998.128-1.928.902-3.588 2.174-5.03C16.215 1.419 17.246.7 18.42.174c.522-.233 1.032-.222 1.522.07.532.317.686.835.687 1.414.003 1.98 0 3.959 0 5.938v4.595h.003Zm-2.25-9.421c-.047.005-.07 0-.083.01-1.804 1.21-2.823 2.887-2.9 5.067-.065 1.851-.055 3.704-.07 5.557-.005.54.317.85.856.852.624.004 1.25-.013 1.873.008.25.009.282-.078.283-.297.01-3.643.027-7.286.042-10.929v-.27.002Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgForkKnife
