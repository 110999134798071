import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import PageNotFound from './components/PageNotFound';
import { ApplicationProvider } from './context/ApplicationContext';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

const Root = () => {
  return (
    <ApplicationProvider>
      <App />
    </ApplicationProvider>
  )
}

const routes = [
  {
    path: "/",
    element: <Navigate to="/jobs" replace />
  },
  {
    path: "/jobs",
    element: <Root/>,
    children: [
      {
        path: ":jobId",
        element: <Root/>
      },
    ],
  },
  {
    path: "*", // --> only match this when no other routes match
    element: <PageNotFound/>
  }
]

const router = createBrowserRouter(routes);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
