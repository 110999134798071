import * as React from "react";

const SvgPDF = (props) => (
  <svg
    width={30}
    height={36}
    fill="none"
    viewBox="0 0 30 36"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m29.625 9.1-8.2-8.25L20.55.5H1.25L0 1.75V15.5h2.5V3h15v8.75L18.75 13h8.75v2.5H30V10l-.375-.9ZM20 10.5V3l7.5 7.5H20ZM1.25 18 0 19.25v15l1.25 1.25h27.5L30 34.25v-15L28.75 18H1.25ZM27.5 30.5V33h-25V20.5h25v10ZM7.5 28h-.8v2.5H5V23h2.65c1.875 0 2.825.9 2.825 2.5a2.351 2.351 0 0 1-.8 1.8c-.62.483-1.39.73-2.175.7Zm-.15-3.625H6.7V26.7h.65c.9 0 1.35-.4 1.35-1.175s-.45-1.15-1.35-1.15ZM17.5 29.45a3.7 3.7 0 0 0 1.1-2.8c0-2.5-1.325-3.65-4-3.65h-2.65v7.5h2.65a4 4 0 0 0 2.9-1.05Zm-3.875-.325v-4.75h.825A2.349 2.349 0 0 1 16.2 25a2.275 2.275 0 0 1 .625 1.675 2.5 2.5 0 0 1-.625 1.8 2.35 2.35 0 0 1-1.725.65h-.85ZM24.75 27.6h-2.425v2.9h-1.7V23h4.35v1.375h-2.65v1.85h2.425V27.6Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPDF;
