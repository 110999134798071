import ModalWrapper from "./ModalWrapper"
import successSvg from "./../../icons/success.svg"

const SuccessModal = (props) => {
    const { currentJob } = props
    return <ModalWrapper {...props}>
        {/* <p className="mb-4 text-lg font-bold text-black">Applied 123</p> */}
        <div className="max-w-[414px] mx-auto text-center">
            <img src={successSvg} className="w-mb-applied-sucecss-icon xl:w-dk-applied-sucecss-icon mx-auto mb-5 xl:mb-4" alt="applied" />
            <h2 className="text-dark-blue text-3.5xl font-bold mb-5 xl:mb-6">Success!</h2>
            <h4 className="text-xl font-semibold mb-5 xl:mb-4">Apply to {currentJob?.title} <br/>({currentJob?.jobType})</h4>
            <p className="mb-5 xl:mb-10">Your application has been sent to {currentJob?.employer?.name}. If you are selected for an Interview they will get in tough with you via email. Good luck!</p>
        </div>
    </ModalWrapper>
}

export default SuccessModal