import { useState, useEffect } from "react";
import Icons from "../../icons";

const NotificationToggle = (props) => {
  const { value, onChange = () => {} } = props;
  const [isChecked, setIsChecked] = useState(value || false);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center -ml-1">
        <Icons.SvgNotify height="35" width="21" />
        <div className="flex flex-col ml-1">
          <p className="text-sm">Notify me</p>
          <p className="text-gray-500 text-[10px] mt-1">
            Receive all notifications and alerts from this app
          </p>
        </div>
      </div>
      <button
        type="button"
        role="switch"
        onClick={handleToggle}
        className={`relative inline-flex w-12 transition-colors duration-500 ease-in-out border-2 border-transparent rounded-full cursor-pointer  shrink-0 h-7 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 mr-1 ${
          isChecked
            ? "bg-primary-blue border-primary-blue"
            : "bg-gray-300 border-gray-300"
        }`}>
        <span
          className={`relative inline-block w-6 h-6 transition duration-200 ease-in-out bg-white rounded-full shadow pointer-events-none ring-0 ${
            isChecked ? "translate-x-3/4" : ""
          }`}>
          <span
            aria-hidden={true}
            className="absolute inset-0 flex items-center justify-center w-full h-full transition-opacity"></span>
        </span>
      </button>
    </div>
  );
};

export default NotificationToggle;
