import SvgBriefcase from "../../icons/SvgBriefcase";
import SvgForkKnife from "../../icons/SvgForkKnife";
import SvgRetail from "../../icons/SvgRetail";
import SvgHospitality from "../../icons/SvgHospitality";
import SvgLabour from "../../icons/SvgLabour";
import SvgCustomerService from "../../icons/SvgCustomerService";
import SvgSaleMarketing from "../../icons/SvgSaleMarketing";
import SvgOfficeAdmin from "../../icons/SvgOfficeAdmin";
import SvgHealthCare from "../../icons/SvgLocation";

const IconComponents = {
  General : SvgBriefcase,
  foodService: SvgForkKnife,
  retail: SvgRetail,
  hospitality: SvgHospitality,
  generalLabour: SvgLabour,
  customerService: SvgCustomerService,
  salesMarketing: SvgSaleMarketing,
  officeAdmin: SvgOfficeAdmin,
  healthCare : SvgHealthCare,

};

export default (props) => {
  const Icon = IconComponents[props.key];
  return typeof Icon !== "undefined" ? (
    <Icon className={props.className} />
  ) : (
    <></>
  );
};
