import * as React from "react"

const SvgJob = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 16h14v3a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3ZM9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2H9V5ZM4 7h16v7a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      stroke="currentColor"
      strokeWidth={3}
      strokeLinejoin="round"
      d="M12 12h.01v.01H12z"
    />
  </svg>
)

export default SvgJob
