import * as React from "react";

const SvgHospitality = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="2 2 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M0 0h32v32H0z" />
    <path
      d="M15.999 7.002c.826 0 1.652-.005 2.478.001.635.005 1.098.316 1.238.812.218.76-.298 1.407-1.15 1.441a11.91 11.91 0 0 1-.848 0c-.143-.005-.193.032-.2.188-.027.691-.035.694.653.84 2.29.49 4.236 1.586 5.776 3.345 1.305 1.492 2.134 3.224 2.436 5.198.112.734.166 1.471.18 2.213.005.197-.038.268-.252.263a34.173 34.173 0 0 0-1.756 0c-.203.005-.263-.048-.26-.255a9.602 9.602 0 0 0-.406-3.026c-.397-1.287-1.105-2.376-2.048-3.321-1.055-1.058-2.296-1.814-3.754-2.152-2.756-.64-5.26-.12-7.437 1.74-1.305 1.114-2.21 2.491-2.63 4.165a9.443 9.443 0 0 0-.28 2.573c.007.217-.046.283-.271.277a40.367 40.367 0 0 0-1.788-.002c-.165.002-.208-.047-.209-.21-.02-3.151.953-5.912 3.254-8.125 1.48-1.424 3.253-2.315 5.272-2.706.102-.02.204-.054.307-.06.157-.008.206-.072.211-.233.023-.708.028-.712-.68-.707-.411.004-.821 0-1.163-.269-.357-.281-.487-.8-.332-1.243.166-.473.556-.74 1.118-.746.847-.006 1.693-.001 2.54-.001ZM15.952 25.058H5.256c-.7 0-1.105-.315-1.232-.95-.114-.564.188-1.077.738-1.23.178-.05.363-.08.555-.08 7.13.005 14.262.003 21.393.003.743 0 1.125.277 1.26.907.167.772-.366 1.347-1.258 1.349-1.81.004-3.617 0-5.427 0h-5.332l-.001.001Z"
      stroke="currentColor"
      strokeWidth={0.15}
      fill="currentColor"
    />
  </svg>
);

export default SvgHospitality;
