import React from "react";
import UpdateJobModalWrapper from "./UpdateJobModalWrapper";

const UpdateJobTypeModal = (props) => {
  return (
    <UpdateJobModalWrapper
      title="What kind of job are you looking for?"
      childComponent="StepThree"
      {...props}
    />
  );
};

export default UpdateJobTypeModal;
