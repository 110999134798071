import * as React from "react";

const SvgSaleMarketing = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox="3 3 27 27"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path fill="none" d="M0 0h32v32H0z" />
    <path
      d="M27.998 16.9v-2.257c0-1.81-1.429-3.123-3.397-3.124h-1.127a416.887 416.887 0 0 0-3.218.004c-.12.003-.146-.011-.149-.013 0 0-.018-.022-.017-.124.015-.937.012-1.89.01-2.81l-.003-.542c-.003-1.67-1.482-3.03-3.296-3.032C15.068 5 13.334 5 11.602 5H7.298c-.233 0-.466.024-.693.073-1.556.333-2.602 1.542-2.603 3.008v8.005c0 1.623 0 3.247-.002 4.87-.001.422.166.708.512.873.347.167.708.123 1.074-.13.474-.327.95-.654 1.426-.98l.014-.01 1.262-.868a.423.423 0 0 1 .246-.086c1.226.005 2.258.007 3.31-.001.031 0 .05.002.059.003.002.01.004.03.004.067-.007.47-.006.944-.005 1.403v.5c0 1.767 1.45 3.1 3.372 3.1h.38c2.526 0 5.137.002 7.74-.001.142 0 .237.027.339.098.581.405 1.175.812 1.754 1.21l.948.65c.208.143.42.217.63.217a.98.98 0 0 0 .474-.127c.31-.17.46-.44.46-.826l-.001-9.147Zm-9.776-.276c0 .858-.61 1.413-1.552 1.413h-1.733c-2.19 0-4.455 0-6.683-.004-.321 0-.588.082-.84.26-.41.288-.82.568-1.254.864l-.282.192V8.164c0-.903.587-1.443 1.568-1.443h9.23c.94 0 1.546.558 1.546 1.418v8.485Zm-4.449 3.217c0-.05.003-.073.006-.082a.38.38 0 0 1 .085-.007c.528.006 1.064.005 1.588.005h1.226c1.021.008 1.884-.338 2.561-1.024l.008-.008.003-.004c.285.316.71.4 1.11.22.36-.162.58-.535.52-.887a.896.896 0 0 0-.823-.732c-.016 0-.029-.003-.04-.004a2.342 2.342 0 0 0 .083-.682c-.002-.464-.002-.929-.002-1.393v-.03c0-.62 0-1.262-.005-1.902 0-.04.003-.06.005-.07a.362.362 0 0 1 .077-.006c.768.004 1.548.004 2.307.004h.207c.668 0 1.335 0 2.003.002.785.002 1.424.575 1.426 1.276.004 2.4.003 4.819.003 7.16v2.74l-1.116-.767-.11-.073a2.97 2.97 0 0 1-.216-.152c-.278-.22-.601-.324-1.026-.324-2.023.007-4.082.006-6.073.006h-2.243c-.96 0-1.558-.548-1.558-1.43v-.563c.001-.416.002-.846-.006-1.272Z"
      fill="currentColor"
    />
    <path
      d="M12.034 10.252h.014a.72.72 0 0 1 .742.682c.028.447.396.773.86.766.481-.012.817-.383.819-.905-.07-.84-.553-1.482-1.4-1.859-.124-.055-.184-.096-.19-.236-.018-.397-.396-.712-.837-.711-.446.003-.802.314-.826.722-.006.12-.039.164-.152.21-1.063.445-1.616 1.444-1.377 2.487.224.979 1.205 1.71 2.334 1.741.441.012.765.294.769.67.004.372-.322.68-.725.688-.415.005-.73-.28-.756-.682-.031-.455-.395-.777-.862-.766a.869.869 0 0 0-.59.24.737.737 0 0 0-.223.566c.04.904.516 1.566 1.415 1.966.115.052.163.085.168.203.002.052.01.102.022.15.088.34.417.586.81.586h.006c.445-.003.808-.32.827-.722.007-.127.057-.169.177-.22 1.047-.452 1.591-1.447 1.354-2.478-.23-.994-1.19-1.71-2.334-1.74a.97.97 0 0 1-.263-.044l-.05-.017c-.274-.104-.453-.331-.456-.611-.003-.367.329-.68.726-.687h-.002ZM23.107 18.942h.002a.898.898 0 0 0 .244-.035c.337-.098.591-.39.595-.726a.74.74 0 0 0-.003-.067v-.01a.647.647 0 0 0-.012-.076.742.742 0 0 0-.123-.273c-.005-.008-.011-.014-.016-.02-.01-.014-.02-.027-.032-.04l-.003-.002a.873.873 0 0 0-.633-.29.87.87 0 0 0-.603.224.733.733 0 0 0-.252.55c.002.415.385.765.837.766l-.001-.002Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSaleMarketing;
