import {
  AnimatePresence,
  motion,
  useAnimation,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useApplicationContext from "../../context/ApplicationContext";

const SwipeableCard = forwardRef(
  (
    {
      children,
      item,
      total,
      onSwipeLeft = () => {},
      onSwipeRight = () => {},
      onClick = () => {},
      setShowAddResumeModal = () => {},
      setSwipedItem = () => {},
      setSwipeDir = () => {},
      style = {},
      className = "",
    },
    ref
  ) => {
    const { user } = useApplicationContext();
    const [isDragged, setIsDragged] = useState(false);

    // To move the card as the user drags the cursor
    const motionValue = useMotionValue(0);

    // To rotate the card as the card moves on drag
    const rotateValue = useTransform(motionValue, [-200, 0, 200], [-50, 0, 50]);

    // Framer animation hook
    const animControls = useAnimation();

    useEffect(() => {
      // Reset animation
      motionValue.set(0);
    }, [total]);

    const swipeLeft = async () => {
      // Animate the card to move left without changing opacity
      await animControls.start({ x: -300 });
      onSwipeLeft(item);
    };

    const swipeRight = async () => {
      const isExternalJobPosting = !!item?.isExternalJob ?? false;

      if (user?.resume || isExternalJobPosting) {
        await animControls.start({ x: 300 });
        onSwipeRight(item);
      } else {
        bounceBack();
        setShowAddResumeModal(true);
        setSwipedItem(item);
        setSwipeDir("--");
      }
    };

    const bounceBack = async () => {
      await animControls.start({ x: 0 });
    };

    useImperativeHandle(ref, () => ({ swipeLeft, swipeRight, bounceBack }));

    const motionStyle = {
      ...style,
      x: motionValue,
      rotate: rotateValue,
    };

    const debounce = (func, delay) => {
      let timeoutId;
      return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    };

    const handleDragEnd = debounce(() => {
      setIsDragged(false);
    }, 100);

    return (
      <AnimatePresence>
        <div className="relative h-full w-full overflow-visible">
         {/* Overlay */}
          <motion.div
            drag="x" // Card can be dragged only on x-axis
            ref={ref}
            className={`relative h-full w-full cursor-pointer ${className} z-10`}
            dragConstraints={{ left: -200, right: 200 }}
            dragDirectionLock
            style={motionStyle}
            animate={animControls}
            onDrag={(e) => {
              if (!isDragged) {
                setIsDragged(true);
              }
            }}
            onDragEnd={(e) => {
              e.stopPropagation();
              handleDragEnd();
            }}
            onClick={(e) => {
              if (!isDragged) {
                onClick();
              }
            }}
            onPanEnd={async (e, info) => {
              setIsDragged(true);
              const { point, offset } = info;
              if (point.x === 0) return; // for drag down, do nothing

              // If the card is dragged only up to 150 on x-axis, bring it back to initial position
              if (Math.abs(offset.x) <= 150) {
                animControls.start({ x: 0 });
              } else {
                // If card is dragged beyond 150, make it disappear
                if (offset.x < 0) {
                  await swipeLeft();
                } else {
                  await swipeRight();
                }
              }
            }}
          >
            {children}
          </motion.div>
        </div>
      </AnimatePresence>
    );
  }
);

export default SwipeableCard;