import React from "react";
import GreyTextIconBlock from "../blocks/GreyTextIconBlock";
import useApplicationContext from "../../context/ApplicationContext";

const iconsMap = {
  General : "SvgBriefcase",
  foodService: "SvgForkKnife",
  retail: "SvgRetail",
  hospitality: "SvgHospitality",
  generalLabour: "SvgLabour",
  customerService: "SvgCustomerService",
  salesMarketing: "SvgSaleMarketing",
  officeAdmin: "SvgOfficeAdmin",
  healthCare : "SvgLocation",
};

const FilterTags = ({
  showJobLocation = true,
  showJobType = true,
  tagClassName = "",
  className = "",
}) => {
  const { user } = useApplicationContext();
  const { postalCode, distance, countries, availabilities, jobCategories, location } =
    user || {};
  const _countries = countries?.filter((a) => a.value);
  const _availabilities = availabilities?.filter((a) => a.value);
  const _jobCategories = jobCategories?.filter((a) => a.value);

  const distanceType = !!user?.countries?.filter((c) => c.key === "us")[0].value
    ? user.distance < 2
      ? "mile"
      : "miles"
    : "km"

  return (
    <div className={`flex flex-wrap ${className}`}>
      {showJobLocation && (
        <>
          {!!_countries &&
            _countries.map((item, idx) => (
              <GreyTextIconBlock
                key={idx}
                className={tagClassName}
                text={item.title}
                iconName="SvgLocationPin"
              />
            ))}
          {/* {!!postalCode && <GreyTextIconBlock
            className={tagClassName}
            text={postalCode}
            iconName="SvgLocationPin"
          />} */}
          {!!location && (
            <GreyTextIconBlock
              className={tagClassName}
              text={location.label}
              iconName="SvgLocationPin"
            />
          )}
          {!!distance && (
            <GreyTextIconBlock
              className={tagClassName}
              text={`${distance}${distanceType}`}
              iconName="SvgPersonWalk"
            />
          )}
        </>
      )}
      {showJobType && (
        <>
          {!!_availabilities &&
            _availabilities.map((item, idx) => (
              <GreyTextIconBlock
                key={idx}
                className={tagClassName}
                text={item.title}
                iconName="SvgJob"
              />
            ))}
          {!!_jobCategories &&
            _jobCategories.map((item, idx) => (
              <GreyTextIconBlock
                key={idx}
                className={tagClassName}
                text={item.title}
                iconName={iconsMap[item.key]}
              />
            ))}
        </>
      )}
    </div>
  );
};

export default FilterTags;
