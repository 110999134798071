import { useState } from 'react';
import Divider from '../common/blocks/Divider';
import NotificationToggle from '../common/blocks/NotificationToggle';
import ResumePicker from '../common/blocks/ResumePicker';
import FilterTags from '../common/jobs/FilterTags';
import ResetIgnoredJobModal from '../common/modals/ResetIgnoredJobModal';
import ResetIgnoredJobSuccessModal from '../common/modals/ResetIgnoredJobSuccessModal';
import UpdateJobLocationModal from '../common/modals/UpdateJobLocationModal';
import UpdateJobTypeModal from '../common/modals/UpdateJobTypeModal';
import UpdatePersonalInfoModal from '../common/modals/UpdatePersonalInfoModal';
import useApplicationContext from '../context/ApplicationContext';
import Icons from '../icons';

import {
  getStorageItem,
  initialUser,
  removeStorageItem,
  setStorageItem,
  storageKey,
} from '../helpers/utils';

const ProfileSetting = ({
  showCrossIcon = false,
  showBackIcon = false,
  onBackClick = () => {},
  onCrossClick = () => {},
}) => {
  const {
    user,
    setUser,
    setIsProfileSetUp,
    setShowUpdateJobTypeModal,
    setShowUpdateJobLocationModal,
  } = useApplicationContext();

  const [showUpdatePersonalInfoModal, setShowUpdatePersonalInfoModal] =
    useState(false);
  const [showResetIgnoredJobsModal, setShowResetIgnoredJobsModal] =
    useState(false);
  const [
    showResetIgnoredJobsSuccessModal,
    setShowResetIgnoredJobsSuccessModal,
  ] = useState(false);

  const handleResetIgnoredJobs = async () => {
    removeStorageItem(storageKey.IGNORED_JOBS);
    setShowResetIgnoredJobsModal(false);
    setShowResetIgnoredJobsSuccessModal(true);
  };

  const handleLogout = () => {
    setUser(initialUser);
    removeStorageItem(storageKey.USER);
    removeStorageItem(storageKey.IS_PROFILE_SETUP);
    removeStorageItem(storageKey.APPLIED_JOBS);
    removeStorageItem(storageKey.IGNORED_JOBS);
    removeStorageItem(storageKey.SAVED_JOBS);
    removeStorageItem(storageKey.VIEW_EXTERNAL_JOB_MODAL);
    setIsProfileSetUp(false);
  };

  const handleUpdateUserLocalStorage = async (key, value) => {
    let _user = await getStorageItem(storageKey.USER);
    _user[key] = value;
    setUser(_user);
    console.log('user', _user);
    await setStorageItem(storageKey.USER, _user);
  };

  return (
    <>
      <div className='flex flex-col max-w-full max-h-screen px-4 py-5 overflow-hidden md:px-8 md:py-7 md:max-w-profile-setup'>
        <div className='flex items-center mb-8 md:mb-6'>
          {showBackIcon && (
            <button className='mr-4 cursor-pointer'>
              <Icons.SvgRightArrow
                className='w-6 h-6 rotate-180 text-icon-color'
                onClick={onBackClick}
              />
            </button>
          )}
          <p className='text-sm font-semibold text-gray-700'>Profile</p>
          {showCrossIcon && (
            <button className='ml-auto cursor-pointer'>
              <Icons.SvgCrossMark
                className='w-6 h-6 text-icon-color'
                onClick={onCrossClick}
              />
            </button>
          )}
        </div>
        <div className='overflow-y-scroll hide-scrollbar'>
          <div className='flex justify-between mb-2'>
            <p
              className={`font-semibold break-normal md:break-all ${
                user?.fullName.length >= 20
                  ? 'text-lg'
                  : user?.fullName.length >= 30
                  ? 'text-base'
                  : 'text-2xl'
              }`}
            >
              {user?.fullName}
            </p>
            <button className='cursor-pointer'>
              <Icons.SvgPencilEdit
                className='pt-1 pl-1 ml-auto text-icon-color'
                onClick={() => setShowUpdatePersonalInfoModal(true)}
              />
            </button>
          </div>
          {user?.phoneNumber && (
            <p className='mb-2 text-sm text-gray-700'>
              Phone: {user?.phoneNumber}
            </p>
          )}
          {user?.email && (
            <p className='mb-4 text-sm text-gray-700 break-normal md:break-all'>
              Email: {user?.email}
            </p>
          )}

          <NotificationToggle
            value={user['notification']}
            onChange={(value) =>
              handleUpdateUserLocalStorage('notification', value)
            }
          />
          <ResumePicker
            className='my-6'
            resume={user['resume']}
            onPickDocument={(data) =>
              handleUpdateUserLocalStorage('resume', data)
            }
            //onPickDocument={(data) => uploadResume("resume", data)}
            onClearDocument={() => handleUpdateUserLocalStorage('resume', null)}
          />
          <div
            className='p-3 mb-6 border rounded cursor-pointer border-light-grey-blue-02'
            onClick={() => setShowUpdateJobLocationModal(true)}
          >
            <div className='flex items-center justify-between mb-5'>
              <p className='text-base'>Update job location</p>
              <button>
                <Icons.SvgAnchorRight className='text-gray-600' />
              </button>
            </div>
            <FilterTags
              showJobLocation={true}
              showJobType={false}
              className='-ml-1.5 !flex-nowrap overflow-hidden'
            />
          </div>
          <div
            className='p-3 mb-6 border rounded cursor-pointer border-light-grey-blue-02'
            onClick={() => setShowUpdateJobTypeModal(true)}
          >
            <div className='flex items-center justify-between mb-5'>
              <p className='text-base'>Update job type</p>
              <button>
                <Icons.SvgAnchorRight className='text-gray-600' />
              </button>
            </div>
            <FilterTags
              showJobLocation={false}
              showJobType={true}
              className='-ml-1.5 !flex-nowrap overflow-hidden'
            />
          </div>
          <button
            className='inline-block w-full py-3 mb-6 text-base text-center border rounded border-light-grey-blue-02'
            onClick={() => setShowResetIgnoredJobsModal(true)}
          >
            Reset Ignored Jobs
          </button>
          <button
            className='inline-block w-full py-3 text-base text-center text-gray-700 bg-gray-100 rounded'
            onClick={handleLogout}
          >
            Reset Profile
          </button>
          <Divider className='my-6' />
          <div className='flex justify-center my-3 text-sm'>
            <p className='mr-3 text-center text-gray-500'>About Swob</p>
            <button>
              <a
                className='text-center text-gray-500'
                target='_blank'
                href='https://www.swobapp.com/terms'
                rel='noreferrer'
              >
                Terms
              </a>
            </button>
            <p className='mx-3 text-center text-gray-500'>/</p>
            <button>
              <a
                className='text-center text-gray-500'
                target='_blank'
                href='https://www.swobapp.com/terms#ca-privacy-policy'
                rel='noreferrer'
              >
                Privacy
              </a>
            </button>
            <p className='mx-3 text-center text-gray-500'>/</p>
            <p className='text-center text-gray-500'>Feedback</p>
          </div>
          <p className='mb-8 text-sm text-center text-gray-500 md:mb-16'>
            Swob 0.0.0
          </p>

          <ResetIgnoredJobModal
            isVisible={showResetIgnoredJobsModal}
            onCrossClick={() => setShowResetIgnoredJobsModal(false)}
            onResetClick={handleResetIgnoredJobs}
            modalClass='!max-w-wrapper'
          />
          <ResetIgnoredJobSuccessModal
            isVisible={showResetIgnoredJobsSuccessModal}
            onCrossClick={() => setShowResetIgnoredJobsSuccessModal(false)}
            modalClass='!max-w-wrapper'
          />
          <UpdatePersonalInfoModal
            isVisible={showUpdatePersonalInfoModal}
            onCrossClick={() => setShowUpdatePersonalInfoModal(false)}
          />
        </div>
      </div>
    </>
  );
};

export default ProfileSetting;
