import { useParams } from "react-router-dom";
import MainSection from './components/MainSection';
import ProfileSetUp from './components/ProfileSetUp';
import useApplicationContext from './context/ApplicationContext';

const App = () => {
  const { isProfileSetUp } = useApplicationContext();
  const { jobId } = useParams();

  return (
    <div className="h-full">
      {(!!jobId || !!isProfileSetUp)
        ? <MainSection />
        : <ProfileSetUp />
      }
    </div>
  );
}

export default App;
