import React from "react";
import Icons from "../../icons";

const JobIsExternal = ({ className }) => {
  return (
    <div className={`flex items-center mb-3 ${className}`}>
      <Icons.SvgExternalJob className="mr-2" />
      <p className="flex-1 text-sm text-gray-500">External job posting</p>
    </div>
  );
};

export default JobIsExternal;
