import * as React from "react"

const SvgGridView = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_1499_4846" fill="white">
      <rect width="7" height="7" rx="1" />
    </mask>
    <rect
      width="7"
      height="7"
      rx="1"
      stroke="currentColor"
      strokeWidth="3"
      mask="url(#path-1-inside-1_1499_4846)"
    />
    <mask id="path-2-inside-2_1499_4846" fill="white">
      <rect y="9" width="7" height="7" rx="1" />
    </mask>
    <rect
      y="9"
      width="7"
      height="7"
      rx="1"
      stroke="currentColor"
      strokeWidth="3"
      mask="url(#path-2-inside-2_1499_4846)"
    />
    <mask id="path-3-inside-3_1499_4846" fill="white">
      <rect x="9" width="7" height="7" rx="1" />
    </mask>
    <rect
      x="9"
      width="7"
      height="7"
      rx="1"
      stroke="currentColor"
      strokeWidth="3"
      mask="url(#path-3-inside-3_1499_4846)"
    />
    <mask id="path-4-inside-4_1499_4846" fill="white">
      <rect x="9" y="9" width="7" height="7" rx="1" />
    </mask>
    <rect
      x="9"
      y="9"
      width="7"
      height="7"
      rx="1"
      stroke="currentColor"
      strokeWidth="3"
      mask="url(#path-4-inside-4_1499_4846)"
    />
  </svg>
);

export default SvgGridView
