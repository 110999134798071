import SwobHeader from "../common/blocks/SwobHeader";
import Svg404 from "../icons/Svg404";

const PageNotFound = () => {

  return (
    <section className="flex flex-col bg-gray-100 min-h-screen">
      <SwobHeader leftPos={true}/>
      <div className="max-w-wrapper md:mx-auto mx-8 flex flex-col items-center md:px-14 mt-14">
        <Svg404 className="w-full h-full max-w-404"/>
        <p className="font-semibold text-2xl md:mb-7 mb-4 text-center">Whoops, page is gone!</p>
        <p className="text-sm text-gray-700 mb-6 text-center">The page you are looking for might have been removed had it’s name changed or is temporarily unavailable.</p>
        <a 
          href="/" 
          className="rounded bg-primary-blue py-3 text-center text-white max-w-go-to-home w-full inline-block"
        >
          Go to main page
        </a>
      </div>
    </section>
  );
};

export default PageNotFound;
