/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ActiveStepBar from "../common/blocks/ActiveStepBar";
import SwobHeader from "../common/blocks/SwobHeader";
import useApplicationContext from "../context/ApplicationContext";
import {
  getStorageItem,
  initialUser,
  setStorageItem,
  storageKey,
} from "../helpers/utils";
import Icons from "../icons";
import StepOne from "./ProfileSetUpSteps/StepOne";
import StepThree from "./ProfileSetUpSteps/StepThree";
import StepTwo from "./ProfileSetUpSteps/StepTwo";

const stepTitles = [
  "Set up your profile and resume",
  "Where would you like to work?",
  "What kind of job are you looking for?",
];

const ProfileSetUp = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [nextBtnIsDisabled, setNextBtnIsDisabled] = useState(true);
  const [formHasError, setFormHasError] = useState(false);
  const { user, setUser, setIsProfileSetUp } = useApplicationContext();

  useEffect(() => {
    setActiveStep(1);
    let localUser = getStorageItem(storageKey.USER);
    setUser(localUser || initialUser);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 50);
  }, [activeStep]);

  useEffect(() => {
    if (activeStep === 1) {
      const filledFieldsS1 = [user?.fullName, user?.phoneNumber, user?.email].filter(
        (i) => !!i
      );
      setNextBtnIsDisabled(filledFieldsS1.length < 3);
    }

    if (activeStep === 2) {
      const filledFieldsS2 = [
        user?.countries?.filter((c) => !!c.value).length,
        user?.postalCode || user?.geoLocation || user?.location,
      ].filter((i) => !!i);
      setNextBtnIsDisabled(filledFieldsS2.length < 2);
    }

    if (activeStep === 3) {
      const filledFieldsS3 = [
        user?.availabilities?.filter((itm) => !!itm.value).length,
        user?.jobCategories?.filter((itm) => !!itm.value).length,
      ].filter((i) => !!i);
      setNextBtnIsDisabled(filledFieldsS3.length < 2);
    }
  }, [user, activeStep]);

  const onBackPress = () => {
    setActiveStep(activeStep - 1);
  };

  const onNextPress = () => {
    if (activeStep < 3) {
      setActiveStep(activeStep + 1);
    } else {
      setStorageItem(storageKey.USER, user);
      setStorageItem(storageKey.IS_PROFILE_SETUP, true);
      setIsProfileSetUp(true);
    }
  };

  return (
    <section>
      <SwobHeader />
      <div className="px-4 mx-auto max-w-step-wrapper">
        <p className="md:mt-8 mt-4 mb-4 md:mb-7 md:text-3.5xl text-xl font-semibold">
          {stepTitles[activeStep - 1]}
        </p>

        <ActiveStepBar user={user} activeStep={activeStep} />

        {activeStep === 1 && <StepOne setFormHasError={setFormHasError} />}
        {activeStep === 2 && <StepTwo setFormHasError={setFormHasError} />}
        {activeStep === 3 && <StepThree />}

        <div
          className={`flex flex-row items-center my-8 ${activeStep === 1 ? "justify-end" : "justify-between"
            }`}>
          {activeStep !== 1 && (
            <button onClick={onBackPress}>
              <p className="ml-4 text-gray-900">Back</p>
            </button>
          )}
          <button
            className={`ml-auto rounded-full ${activeStep === 3
              ? `text-white transition duration-300 ${`${formHasError || nextBtnIsDisabled
                ? "bg-gray-300 border-gray-300 cursor-not-allowed"
                : "bg-primary-blue"
              }`}`
              : `border ${formHasError || nextBtnIsDisabled
                ? "border-gray-300 cursor-not-allowed"
                : "border-primary-blue"
              }`
              }`}
            onClick={onNextPress}
            disabled={formHasError || nextBtnIsDisabled}>
            <div className="flex flex-row items-center justify-between px-5 py-3 md:w-44">
              <p
                className={`${activeStep === 3
                  ? "text-white"
                  : `${formHasError || nextBtnIsDisabled
                    ? "text-gray-500"
                    : "text-gray-900"
                  }`
                  }`}>
                {activeStep === 3 ? "Get Started" : "Next"}
              </p>
              <Icons.SvgRightArrow
                className={`${activeStep === 3
                  ? "text-white"
                  : `${formHasError || nextBtnIsDisabled
                    ? "text-gray-500"
                    : "text-gray-800"
                  }`
                  }`}
              />
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default ProfileSetUp;
