import React, { createContext, useContext, useEffect, useState } from 'react';
import { getStorageItem, initialUser, storageKey } from '../helpers/utils';
import useUser from '../hooks/useUser';

const ApplicationContext = createContext(null);

const ApplicationProvider = ({ children }) => {
  const { user, setUser, updateUserValue } = useUser();
  const [isProfileSetUp, setIsProfileSetUp] = useState(true);

  const [refreshJobsData, setRefreshJobsData] = useState(0);

  const [showUpdateJobLocationModal, setShowUpdateJobLocationModal] =
    useState(false);
  const [showUpdateJobTypeModal, setShowUpdateJobTypeModal] = useState(false);

  useEffect(() => {
    checkIfProfileIsSetup();
  }, []);

  const checkIfProfileIsSetup = async () => {
    const status = await getStorageItem(storageKey.IS_PROFILE_SETUP);
    let localUser = await getStorageItem(storageKey.USER);
    setUser(localUser || initialUser);
    setIsProfileSetUp(status);
  };

  const context = {
    user,
    setUser,
    updateUserValue,
    isProfileSetUp,
    setIsProfileSetUp,
    refreshJobsData,
    setRefreshJobsData,
    showUpdateJobLocationModal,
    setShowUpdateJobLocationModal,
    showUpdateJobTypeModal,
    setShowUpdateJobTypeModal,
  };

  return (
    <ApplicationContext.Provider value={context}>
      {children}
    </ApplicationContext.Provider>
  );
};

const useApplicationContext = () => useContext(ApplicationContext);

export default useApplicationContext;

export { ApplicationProvider };
