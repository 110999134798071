import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={56}
    height={56}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 56"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.467 15.667c-4.98 0-8.995 3.875-9.133 8.857v.276c0 2.354.968 4.706 2.63 6.37l.074.072a.506.506 0 0 0 .04.073c.04.06.099.126.174.202.157.16.452.433 1.01.931 1.117 1.001 3.316 2.934 7.644 6.73.605.599 1.49.889 2.228.889.881 0 1.608-.297 2.304-.714l.027-.017.024-.02c4.117-3.685 6.32-5.649 7.495-6.697.992-.885 1.253-1.118 1.319-1.183l.133-.133.01-.01c1.65-1.788 2.62-4 2.62-6.493l.001-.267v-.018c-.277-4.966-4.146-8.848-9.133-8.848-1.452 0-3.499.729-4.8 2.205-1.281-1.471-3.209-2.205-4.667-2.205Zm-6.733 9.266v-.393c.13-3.417 3.046-6.207 6.466-6.207.462 0 1.106.178 1.72.524.611.345 1.154.835 1.449 1.425.33.662.99.985 1.631.985s1.3-.322 1.632-.984l.005-.012c.293-.644.86-1.13 1.485-1.46.628-.329 1.275-.478 1.678-.478 3.554 0 6.337 2.657 6.467 6.206v.261a6.434 6.434 0 0 1-1.903 4.564l-.12.12c-.702.565-3.637 3.101-8.666 7.6l-.014.014c-.146.146-.297.202-.43.202-.134 0-.285-.056-.431-.202l-.009-.009-.01-.008a164.212 164.212 0 0 0-6.812-5.594l-.53-.415c-.4-.315-.646-.516-.765-.634l-.933-1.066-.008-.008c-1.145-1.144-1.902-2.795-1.902-4.43Z"
      fill="#FF7763"
    />
    <rect
      x={1.5}
      y={1.5}
      width={53}
      height={53}
      rx={26.5}
      stroke="#F87"
      strokeWidth={3}
    />
  </svg>
);

export default SvgComponent;
