import { useState, useCallback, useEffect } from "react";
import { setStorageItem, storageKey } from "../helpers/utils";

const useUser = (initialValue = {}) => {
  const [ user, setUser ] = useState(initialValue);

  const updateUserValue = useCallback((key, value) => {
    let _user = { ...user };
    _user[key] = value;
    setStorageItem(storageKey.USER, _user)
    setUser(_user);
  });
  
  return {
    user,
    setUser,
    updateUserValue
  };
};

export default useUser;
