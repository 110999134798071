import React from "react";
import Icons from "../../icons";

const SwipeToast = ({
  swipeDir,
  swipeLeftMessage = "NOT INTERESTED",
  swipeRightMessage = "APPLIED",
}) => {
  const Icon = swipeDir === "left" ? Icons.SvgCrossCircle : Icons.SvgCheckMark;
  let message = swipeDir === "left" ? swipeLeftMessage : swipeRightMessage;
  return !!message ? (
    <div className="absolute top-0 left-0 z-20 flex items-center justify-center w-full h-full">
      <div
        className={`flex p-4 rounded-full justify-center items-center  ${
          swipeDir === "right" ? "bg-applied" : ""
        }`}>
        <Icons.SvgCheckMark
          className={swipeDir === "right" ? "text-white" : "text-transparent"}
        />
        <p
          className={`${"ml-2 text-xs font-medium md:font-semibold md:text-base"} ${
            swipeDir === "right" ? "text-white" : "text-transparent"
          }`}>
          {message}
        </p>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SwipeToast;
