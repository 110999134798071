import React, { useState } from "react";
import { storageKey, getStorageItem, setStorageItem } from "../../helpers/utils";
import useApplicationContext from "../../context/ApplicationContext";
import ModalWrapper from "../modals/ModalWrapper";
import ResumePicker from "../blocks/ResumePicker";
import BaseInput from "../blocks/BaseInput";
import { validateField } from "../../helpers/userSchema";
import { set } from "lodash";

const AddResumeModal = (props) => {
  const { onApplyClick, currentJob, swipedItem, setSwipeDir = () => {} } = props;
  const { user, updateUserValue } = useApplicationContext();
  const [formErrors, setFormErrors] = useState({});

  const handleUpdateUserLocalStorage = (key, value) => {
    let _user = getStorageItem(storageKey.USER) || {};
    _user[key] = value;
    updateUserValue(key, value);
    setStorageItem(storageKey.USER, _user);
  };

  return (
    <ModalWrapper {...props}>
      <div className="w-full px-4 py-4 overflow-y-auto md:px-28 max-h-[540px]">
        <div className="flex flex-row items-center justify-end"></div>
        <h2 className="mb-8 text-xl font-semibold text-center">
          Apply to {swipedItem?.title}
        </h2>
        {/* <p className="text-sm text-center text-gray-500 py-7">
          To apply for the job, we require you to upload your resume. Your resume will be
          saved for next job application.
        </p> */}
        <div className="w-full pb-6">
          <p className="mb-4 font-medium text-gray-900">
            Personal Info<span className="text-sm text-red">*</span>
          </p>
          <BaseInput
            required
            id="s1-fullName"
            name="fullName"
            // placeholder="Full name here"
            label="Full name"
            value={user?.fullName}
            onChange={(value) => updateUserValue("fullName", value)}
            onBlur={() => validateField(user, "fullName", setFormErrors, formErrors)}
            errormessage={formErrors?.fullName}
            inputClassName="z-20"
            subLabelClassName="z-10"
          />
          <BaseInput
            required
            id="s1-phoneNumber"
            name="phoneNumber"
            // placeholder="Phone number"
            label="Phone number"
            value={user?.phoneNumber}
            onChange={(value) => updateUserValue("phoneNumber", value)}
            onBlur={() => validateField(user, "phoneNumber", setFormErrors, formErrors)}
            errormessage={formErrors?.phoneNumber}
            inputClassName="z-20"
            subLabelClassName="z-10"
          />
          <BaseInput
            required
            id="s1-email"
            name="email"
            type="email"
            // placeholder="Your email"
            label="Your email"
            value={user?.email}
            onChange={(value) => updateUserValue("email", value)}
            onBlur={() => validateField(user, "email", setFormErrors, formErrors)}
            errormessage={formErrors.email}
            inputClassName="z-20"
            subLabelClassName="z-10"
          />
        </div>
        <div className="w-full pb-10">
          <p className="mb-4 font-medium text-gray-900">Add your resume</p>
          <ResumePicker
            resume={user?.resume}
            showApplyButton={true}
            onPickDocument={(data) => handleUpdateUserLocalStorage("resume", data)}
            onClearDocument={() => handleUpdateUserLocalStorage("resume", null)}
            // onApplyClick={onApplyClick}
          />
        </div>
        {!!user?.fullName && !!user?.phoneNumber && !!user?.email && !!user?.resume && (
          <button
            onClick={(e) => {
              // If this stopPropagation is enabled, it will prevent the swipeToast from showing //
              // e.stopPropagation();
              onApplyClick(e);
              setSwipeDir("right");
            }}
            className="w-full px-2 py-3 text-white rounded bg-primary-blue">
            Apply
          </button>
        )}
      </div>
    </ModalWrapper>
  );
};

export default AddResumeModal;
