import React from "react";
import Icons from "../../icons";

const SwipeDirectionInfo = ({className = ""}) => {
  return (
    <div className={`flex items-center md:justify-start justify-evenly border-b border-gray-300 ${className}`}>
      <div className="flex items-center mr-3">
        <Icons.SvgCrossCircle className="mr-2 text-red" />
        <p className="text-gray-500 text-sm font-semibold">
          Swipe left to ignore
        </p>
      </div>
      <div className="flex items-center">
        <Icons.SvgCheckCircle className="mr-2 text-primary-blue" />
        <p className="text-gray-500 text-sm font-semibold">
          Swipe right to apply
        </p>
      </div>
    </div>
  );
};

export default SwipeDirectionInfo;
