import { useEffect, useState } from "react";
import BaseInput from "../../common/blocks/BaseInput";
import NotificationToggle from "../../common/blocks/NotificationToggle";
import ResumePicker from "../../common/blocks/ResumePicker";
import useApplicationContext from "../../context/ApplicationContext";
import { validateField } from "../../helpers/userSchema";

const StepOne = (props) => {
  const [formErrors, setFormErrors] = useState({});
  const { user, updateUserValue } = useApplicationContext();
  const { isFromUpdate = false, setFormHasError = () => { } } = props;

  useEffect(() => {
    let keys = Object.keys(formErrors);
    setFormHasError(!!keys.filter(i => !!formErrors[i]).length);
  }, [formErrors])

  return (
    <div className="mb-auto">
      <p className="mb-4 text-base">
        Personal Info<span className="text-red">*</span>
      </p>
      <BaseInput
        required
        id="s1-fullName"
        name="fullName"
        // placeholder="Full name here"
        label="Full name"
        value={user?.fullName}
        onChange={(value) => updateUserValue("fullName", value)}
        onBlur={() => validateField(user, "fullName", setFormErrors, formErrors)}
        errormessage={formErrors.fullName}
      />
      <BaseInput
        required
        id="s1-phoneNumber"
        name="phoneNumber"
        // placeholder="Phone number"
        label="Phone number"
        value={user?.phoneNumber}
        onChange={(value) => updateUserValue("phoneNumber", value)}
        onBlur={() => validateField(user, "phoneNumber", setFormErrors, formErrors)}
        errormessage={formErrors.phoneNumber}
      />
      <BaseInput
        required
        id="s1-email"
        name="email"
        type="email"
        // placeholder="Your email"
        label="Your email"
        value={user?.email}
        onChange={(value) => updateUserValue("email", value)}
        onBlur={() => validateField(user, "email", setFormErrors, formErrors)}
        errormessage={formErrors.email}
      />
      {!isFromUpdate && (
        <>
          <p className="mb-4 text-base">Add your resume</p>
          <ResumePicker
            resume={user?.resume}
            onPickDocument={(data) => updateUserValue("resume", data)}
            onClearDocument={() => updateUserValue("resume", null)}
          />
          <p className="my-4 text-base">Notifications</p>
          <NotificationToggle
            value={user?.notification}
            onChange={(value) => updateUserValue("notification", value)}
          />
        </>
      )}
    </div>
  );
};

export default StepOne;
