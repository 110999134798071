import { useState, useEffect } from "react";

const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1312,
  "2xl": 1440,
}

const useBreakpoint = (breakpoint) => {
  const [status, setStatus] = useState(false);
  let bp = screens[breakpoint];

  const resize = () => {
    setStatus(window.innerWidth >= bp)
  }

  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [])

  return status;
}

export default useBreakpoint;