import useBreakpoint from "../../hooks/useBreakpoint";
import Icons from "../../icons";

const SwobHeader = ({ leftPos = false, className = "" }) => {
  const isDesktop = useBreakpoint("md");
  return (
    <div className={`border-b border-gray-300 md:pb-6 pb-3 pt-11 bg-white ${className}`}>
      <a href="/" className={`inline-block w-20 h-6 mx-4 md:mb-3 md:w-36 ${leftPos ? 'md:mx-16' : 'md:mx-auto flex justify-center'}`}>
        <Icons.SvgSwobLogo height={isDesktop ? 42 : 24} width={isDesktop ? 142 : 82} />
      </a>
    </div>
  );
};

export default SwobHeader;
