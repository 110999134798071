import { useState } from "react";
import { getStorageItem, setStorageItem, storageKey } from "../../helpers/utils";

import useApplicationContext from "../../context/ApplicationContext";

import StepOne from "../../components/ProfileSetUpSteps/StepOne";
import StepThree from "../../components/ProfileSetUpSteps/StepThree";
import StepTwo from "../../components/ProfileSetUpSteps/StepTwo";
import SwobHeader from "../blocks/SwobHeader";

import Icons from "../../icons";

const UpdateJobModalWrapper = ({
  title = "",
  isVisible = false,
  onCrossClick = () => { },
  className = "",
  childComponent,
}) => {
  const [formHasError, setFormHasError] = useState(false);
  const { user, setUser, setRefreshJobsData } = useApplicationContext();
  const componentsMap = {
    StepOne: StepOne,
    StepTwo: StepTwo,
    StepThree: StepThree,
  };

  const ChildComponent = componentsMap[childComponent];

  const handleOnCrossClick = (e) => {
    e.stopPropagation();
    let localUser = getStorageItem(storageKey.USER);
    setUser(localUser);
    onCrossClick();
  };

  const handleOnSaveClick = (e) => {
    if (!formHasError) {
      e.stopPropagation();
      setStorageItem(storageKey.USER, user);
      setUser(user);
      onCrossClick();
      if (childComponent !== "StepOne") {
        setRefreshJobsData(Math.floor(Math.random() * 1001))
        window.dispatchEvent(new Event("should-get-jobs"));
      }
    }
  };

  if (!isVisible) return <></>;

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full bg-white modal overflow-y-auto ${className}`}
      style={{ zIndex: 10000 }}>
      <SwobHeader className="hidden md:block" />
      <div className="flex flex-col w-full h-full px-4 mx-auto md:h-fit max-w-step-wrapper">
        <div className="flex flex-row justify-end w-full mt-8 mb-6 md:my-12">
          <button onClick={handleOnCrossClick}>
            <div className="flex flex-row items-center">
              <p className="hidden pr-3 text-gray-500 md:block">Close</p>
              <Icons.SvgCrossMark className="text-black" height={20} width={20} />
            </div>
          </button>
        </div>

        <p className="mb-10 md:text-3.5xl text-[20px] font-semibold text-gray-900">
          {title}
        </p>
        <div className="flex flex-col h-full md:justify-between">
          <>{!!user && !!ChildComponent && <ChildComponent isFromUpdate setFormHasError={setFormHasError} />}</>
          <button
            className="w-full py-4 mt-8 mb-3 text-center text-white border rounded-md bg-primary-blue"
            onClick={handleOnSaveClick}
            disabled={formHasError}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateJobModalWrapper;
