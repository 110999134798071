import { useEffect, useRef, useState } from "react";
import Slider from "react-input-slider";
import * as yup from "yup";
import ButtonCheck from "../../common/blocks/ButtonCheck";
import InputLocation from "../../common/blocks/InputLocation";
import useApplicationContext from "../../context/ApplicationContext";
import Icons from "../../icons";

const StepTwo = (props) => {
  const [formErrors, setFormErrors] = useState({});
  const { setFormHasError = () => { } } = props;
  const { user, updateUserValue } = useApplicationContext();
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const countries = user.countries;
  const defaultDistance = user?.distance || 0;
  const [sliderDistance, _setSliderDistance] = useState(defaultDistance);
  const postalCodeRegex = /^((\d{5}-\d{4})|(\d{5})|([A-Z|a-z]\d[A-Z|a-z]\d[A-Z|a-z]\d))$/;

  const schema = yup.object().shape({
    postalCode: yup
      .string()
      .nullable()
      .default(user?.postalCode)
      .label("Postal code")
      .required()
      // .matches(postalCodeRegex, "Postal code is not valid")
      .min(5, "Must be a valid postal code"),
    // .max(6, "Must be a valid postal code"),
  });

  useEffect(() => {
    let keys = Object.keys(formErrors);
    setFormHasError(!!keys.filter((i) => !!formErrors[i]).length);
  }, [formErrors]);

  const validateField = async (field) => {
    let obj = {};
    obj[field] = "";
    schema
      .validateAt(field, user[field])
      .then(() => {
        // on success
        setFormErrors({ ...formErrors, ...obj });
        if (field === "postalCode") {
          handleGetGeoLocationFromPostalCode(user[field]);
        }
      })
      .catch((err) => {
        // on error
        obj[field] = err.message;
        setFormErrors({ ...formErrors, ...obj });
      });
  };

  // Using State Ref hook for Accessing React State in Event
  const sliderStateRef = useRef(sliderDistance);
  const setSliderDistance = (data) => {
    sliderStateRef.current = data;
    _setSliderDistance(data);
  };

  const handleCountriesChange = (key, value) => {
    let _updated = countries.map((c) => ({ ...c, value: false }));
    _updated = _updated.map((c) => (c.key === key ? { ...c, value } : c));
    updateUserValue("countries", _updated);
  };

  const handleGetGeoLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          let { coords } = position;
          if (coords) {
            const { latitude, longitude } = coords;
            const geoPoint = { latitude, longitude };
            updateUserValue("parseGeoLocation", geoPoint);
            updateUserValue("geoLocation", geoPoint);
            try {
              let response = await fetch(
                "https://maps.google.com/maps/api/geocode/json?latlng=" +
                latitude +
                "," +
                longitude +
                "&result_type=street_address&key=" +
                googleMapsApiKey
              );
              let res = await response.json();
              let found = !!res.results?.length > 0 ? res.results[0] : null;
              if (!!found) {
                let postalCode = found.address_components?.filter(
                  (ac) => ac.types.indexOf("postal_code") !== -1
                );
                // !!postalCode && updateUserValue("postalCode", postalCode.long_name);
                updateUserValue("location", {
                  label: found.formatted_address,
                  value: {
                    // ... can be anything
                  },
                  postalCode: postalCode?.long_name,
                  latitude: found?.geometry?.location?.lat,
                  longitude: found?.geometry?.location?.lng,
                });
              }
            } catch (error) {
              console.log({ error });
            }
          }
        },
        (error) => {
          console.log({ error });
        }
      );
    } else {
      console.log("Geolocation is not supported by this broswer.");
    }
  };

  const handleGetGeoLocationFromPostalCode = async (postCode) => {
    try {
      let response = await fetch(
        "https://maps.google.com/maps/api/geocode/json?address=" +
        postCode +
        "&key=" +
        googleMapsApiKey
      );

      let resp = await response.json();
      console.log("res ", resp);
      let geoPoint;
      if (resp.results.length > 0) {
        let cords = resp.results[0].geometry.location;
        const { lat, lng } = cords;
        geoPoint = { latitude: lat, longitude: lng };
      } else {
        geoPoint = { latitude: 43.7137562, longitude: -79.49007379999999 };
      }
      console.log({ geoPoint });
      if (!!geoPoint) {
        updateUserValue("parseGeoLocation", geoPoint);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div className="w-full mb-auto">
      <p className="mb-4 text-base font-medium">
        Country<span className="text-red">*</span>
      </p>

      {!!countries &&
        countries.map((c, idx) => (
          <ButtonCheck
            key={idx}
            title={c.title}
            name="countries"
            value={c.value}
            onChange={(value) => handleCountriesChange(c.key, value)}
          />
        ))}

      <p className="mt-6 mb-4 text-base font-medium">
        Your location<span className="text-red">*</span>
      </p>
     
      <InputLocation
        value={user?.location}
        countries={user?.countries}
        onChange={updateUserValue}
      />

      <button
        className="flex items-center justify-start h-10 mb-4"
        onClick={handleGetGeoLocation}>
        <Icons.SvgLocationPick height="35" width="21" />
        <p className="pl-2 text-base text-gray-700">Use my location</p>
      </button>

      <p className="mt-6 mb-4 text-base font-medium">Adjust distance</p>
      <div className="flex items-center w-full mb-16">
        <Icons.SvgPersonWalk className="text-primary-blue" height="25" width="16" />
        <div className="w-full px-3">
          <Slider
            id="distance-slider"
            axis="x"
            x={sliderDistance}
            onChange={({ x }) => setSliderDistance(x)}
            onDragEnd={() => {
              updateUserValue("distance", Math.round(sliderStateRef.current));
            }}
            styles={{
              track: {
                backgroundColor: "#D7D7D7",
                width: "100%",
              },
              active: {
                backgroundColor: "#1C7FB9",
              },
              thumb: {
                width: 16,
                height: 32,
                border: "1px solid #1C7FB9",
                borderRadius: "12px",
              },
            }}
          />
        </div>
        <Icons.SvgCar className="text-gray-400" height="21" width="24" />
      </div>

      <div
        className={`flex flex-row justify-center rounded ${sliderDistance > 0
          ? "bg-light-grey-blue-03 border border-light-grey-blue-03"
          : "border border-gray-300"
          }`}>
        <p
          className={`py-3 text-base ${sliderDistance > 0 ? "text-gray-900" : "text-gray-500"
            }`}>
          {sliderDistance > 0 ? "Within" : ""} {sliderDistance}{" "}
          {!!user?.countries?.filter((c) => c.key === "us")[0].value
            ? sliderDistance < 2
              ? "mile"
              : "miles"
            : "km"}
        </p>
      </div>
    </div>
  );
};

export default StepTwo;
