import * as React from "react";

const SvgCrossCircle = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_1471_5546)">
      <circle
        cx="12"
        cy="12"
        r="11"
        fill="white"
        stroke="currentColor"
        strokeWidth={props.strokeWidth || 2}
      />
      <g clipPath="url(#clip1_1471_5546)">
        <path
          d="M7.60156 7.60156L16.0391 16.0391M7.60156 16.0391L16.0391 7.60156"
          stroke="currentColor"
          strokeWidth={props.strokeWidth || 2.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1471_5546">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_1471_5546">
        <rect
          width="15.36"
          height="15.36"
          fill="white"
          transform="translate(4.32031 4.32031)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCrossCircle;
