const ActiveStepBar = (props) => {
  const { user, activeStep } = props;

  const filledFieldsS1 = [
    user?.fullName,
    user?.phoneNumber,
    user?.email,
  ].filter((i) => !!i);

  const filledFieldsS2 = [
    user?.countries?.filter((c) => !!c.value).length,
    user?.postalCode || user?.geoLocation || user.location,
  ].filter((i) => !!i);

  const filledFieldsS3 = [
    user?.availabilities?.filter((c) => !!c.value).length,
    user?.jobCategories?.filter((jc) => !!jc.value).length,
  ].filter((i) => !!i);

  const activePerOne = activeStep > 1 ? 100 : (filledFieldsS1.length / 3) * 100;
  const activePerTwo =
    activeStep > 2 || filledFieldsS2.length == 2
      ? 100
      : activeStep != 2
      ? 0
      : (filledFieldsS2.length / 2) * 100;
  const activePerThree = (filledFieldsS3.length / 2) * 100;

  return (
    <div className="grid grid-cols-3 gap-3 w-full mb-8">
      <ProgressBar activePercentage={activePerOne} />
      <ProgressBar activePercentage={activePerTwo} className="mx-2" />
      <ProgressBar activePercentage={activePerThree} />
    </div>
  );
};

const ProgressBar = (props) => {
  return (
    <div className="h-2 bg-gray-100" style={props.style}>
      <div
        className="h-2 transition-all bg-primary-blue"
        style={{ width: (props.activePercentage || 0) + "%" }}
      />
    </div>
  );
};

export default ActiveStepBar;
