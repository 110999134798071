import React, { useState, useEffect } from "react";

import Icons from "../../icons";

const ButtonCheck = (props) => {
  const { title, value, icon, onChange = () => {} } = props;
  const [isChecked, setIsChecked] = useState(value || false);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const handlePress = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <div className="justify-center w-full">
      <button
        className={`w-full flex flex-row items-center justify-between h-12 py-2 pl-1 pr-2 mb-4 border rounded ${
          isChecked ? "border-primary-blue" : "border-gray-300"
        }`}
        onClick={() => handlePress()}>
        <div className="flex flex-row">
          {icon ? <div className="pl-2">{icon}</div> : null}
          <p
            className={`ml-3 text-base text-gray-500 ${
              isChecked ? "text-primary-blue" : "text-gray-500"
            }`}>
            {title}
          </p>
        </div>
        <Icons.SvgCheckMark
          className={`${isChecked ? "text-primary-blue" : "text-gray-300"}`}
          height="35"
          width="21"
        />
      </button>
    </div>
  );
};

export default ButtonCheck;
