import { useCallback } from "react";
import { uploadUserResume } from "../../helpers/utils";

function FileInput({ resume, onPickDocument = () => { } }) {
  const buttonText = !!resume ? "Re-upload" : "Upload";

  const handleFileChange = useCallback(
    (event) => {

      const fileInput = event.target;
      const file = fileInput.files[0];

      uploadUserResume(file, onPickDocument);
      fileInput.value = "";
    },
    [onPickDocument]
  );

  return (
    <div className="file-input-container">
      <input
        type="file"
        id="file-selector"
        accept=".pdf"
        onChange={handleFileChange}
        className="w-full"
      />
      <label
        htmlFor="file-selector"
        className={`flex w-full justify-center px-2 py-3 rounded text-white mr-2 cursor-pointer ${!!resume ? "bg-primary-blue" : "bg-gray-400"
          }`}>
        <span>{buttonText}</span>
      </label>
    </div>
  );
}

export default FileInput;
