import * as React from "react";

const SvgExternalJob = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_1349_15798)" />
    <g clipPath="url(#clip0_1349_15798)">
      <path
        d="M11.3437 6.3125H6.75V14.8125C6.75 15.9171 7.64543 16.8125 8.75 16.8125H15.25C16.3546 16.8125 17.25 15.9171 17.25 14.8125V12.2187"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0312 13.5312L17.25 6.3125"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9688 6.3125H17.25V9.59375"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1349_15798"
        x1="4.99342"
        y1="-76.3846"
        x2="39.1033"
        y2="-73.0727"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1C7FB9" />
        <stop offset="0.893229" stopColor="#95CBEE" />
        <stop offset="1" stopColor="#95CBEE" />
      </linearGradient>
      <clipPath id="clip0_1349_15798">
        <rect width="14" height="14" fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgExternalJob;
