import * as React from "react";

const Svg404 = (props) => (
  <svg
    width="449"
    height="430"
    viewBox="0 0 449 430"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M175.931 57.2905C212.15 48.9384 248.049 35.8921 284.006 44.9803C322.312 54.6621 357.996 76.3226 379.874 108.837C401.723 141.309 402.505 181.843 402.393 220.926C402.277 261.526 406.671 307.303 379.217 337.618C352.082 367.58 305.657 366.012 265.51 372.073C230.668 377.332 196.396 379.487 162.452 370.341C125.796 360.464 84.4653 350.074 64.7731 317.986C45.2632 286.195 63.0666 246.216 63.4117 208.955C63.7723 170.026 43.4995 126.517 66.8381 95.0592C90.2159 63.5482 137.483 66.1565 175.931 57.2905Z"
      fill="#E8EAEE"
    />
    <path
      d="M277.968 263.413H234.221H138.396V156.556V140.305H330.047V156.556V209.984"
      stroke="#1C7FB9"
      strokeWidth="1.5"
      strokeDasharray="6 6"
    />
    <path
      d="M138.007 133.772V111.724C138.007 108.963 140.245 106.724 143.007 106.724H234.221H325.436C328.198 106.724 330.436 108.963 330.436 111.724V133.772H138.007Z"
      stroke="#1C7FB9"
      strokeWidth="2"
    />
    <circle cx="152.006" cy="119.29" r="2.5" fill="#1C7FB9" />
    <circle cx="161.006" cy="119.29" r="2.5" fill="#1C7FB9" />
    <circle cx="170.006" cy="119.29" r="2.5" fill="#1C7FB9" />
    <g clipPath="url(#clip0_1856_28613)">
      <rect
        x="289.773"
        y="223.299"
        width="61.1064"
        height="60.8624"
        rx="30.4312"
        fill="#1C7FB9"
      />
      <path
        d="M320.326 250.965L328.665 242.659C329.43 241.897 330.666 241.897 331.431 242.659V242.659C332.2 243.425 332.2 244.67 331.431 245.436L323.103 253.731L331.431 262.025C332.2 262.791 332.2 264.036 331.431 264.802V264.802C330.666 265.564 329.43 265.564 328.665 264.802L320.326 256.497L311.987 264.802C311.223 265.564 309.986 265.564 309.221 264.802V264.802C308.452 264.036 308.452 262.791 309.221 262.025L317.549 253.731L309.221 245.436C308.452 244.67 308.452 243.425 309.221 242.659V242.659C309.986 241.897 311.223 241.897 311.987 242.659L320.326 250.965Z"
        fill="white"
      />
    </g>
    <path
      d="M213.597 177.653L194.981 196.194"
      stroke="#1C7FB9"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M213.597 196.194L194.981 177.653"
      stroke="#1C7FB9"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M275.647 177.653L257.032 196.194"
      stroke="#1C7FB9"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M275.647 196.194L257.032 177.653"
      stroke="#1C7FB9"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M193.947 223.299C196.982 226.988 200.017 226.988 203.051 223.299C206.086 219.611 209.121 219.611 212.156 223.299C215.19 226.988 218.225 226.988 221.26 223.299C224.295 219.611 227.329 219.611 230.364 223.299C233.399 226.988 236.434 226.988 239.468 223.299C242.503 219.611 245.538 219.611 248.573 223.299C251.607 226.988 254.642 226.988 257.677 223.299C260.712 219.611 263.746 219.611 266.781 223.299C269.816 226.988 272.851 226.988 275.885 223.299"
      stroke="#1C7FB9"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M118.259 371.202V358.304L150.637 307.353H161.771V325.206H155.182L134.77 357.471V358.077H180.782V371.202H118.259ZM155.485 384.819V367.268L155.788 361.557V307.353H171.163V384.819H155.485Z"
      fill="#E8503C"
    />
    <path
      d="M224.25 386.521C217.736 386.496 212.131 384.895 207.435 381.717C202.765 378.54 199.167 373.938 196.643 367.911C194.143 361.884 192.906 354.634 192.931 346.162C192.931 337.714 194.181 330.514 196.68 324.563C199.205 318.612 202.803 314.086 207.473 310.984C212.169 307.857 217.761 306.294 224.25 306.294C230.738 306.294 236.317 307.857 240.988 310.984C245.684 314.111 249.294 318.65 251.819 324.601C254.344 330.527 255.593 337.714 255.568 346.162C255.568 354.66 254.306 361.922 251.781 367.949C249.282 373.976 245.697 378.578 241.026 381.755C236.355 384.933 230.763 386.521 224.25 386.521ZM224.25 372.942C228.693 372.942 232.24 370.71 234.891 366.247C237.542 361.783 238.855 355.088 238.83 346.162C238.83 340.286 238.224 335.394 237.012 331.485C235.825 327.577 234.134 324.639 231.937 322.672C229.766 320.705 227.203 319.722 224.25 319.722C219.831 319.722 216.297 321.928 213.646 326.341C210.995 330.754 209.657 337.361 209.632 346.162C209.632 352.113 210.225 357.08 211.412 361.065C212.624 365.024 214.328 367.999 216.524 369.992C218.721 371.958 221.296 372.942 224.25 372.942Z"
      fill="#E8503C"
    />
    <path
      d="M268.219 371.202V358.304L300.598 307.353H311.732V325.206H305.142L284.73 357.471V358.077H330.742V371.202H268.219ZM305.445 384.819V367.268L305.748 361.557V307.353H321.123V384.819H305.445Z"
      fill="#E8503C"
    />
    <defs>
      <clipPath id="clip0_1856_28613">
        <rect
          x="289.773"
          y="223.299"
          width="61.1064"
          height="60.8624"
          rx="30.4312"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Svg404;
