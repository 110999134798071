import * as React from "react";

const HeartIconLiked = (props) => (
  <svg
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m25.428 17.523-7.543 7.542a2.667 2.667 0 0 1-3.77 0l-7.543-7.542A6.667 6.667 0 1 1 16 8.095"
      stroke="#F87"
      strokeWidth={2.667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.428 17.523A6.667 6.667 0 1 0 16 8.095"
      stroke="#F87"
      strokeWidth={2.667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 6.5 16 8l2-.5L20.5 6l4 1.5 2.5 3v4L18 25l-2 .5h-1l-2.5-2-2-2.5L7 18l-1.5-2.5L5 13l.5-3L8 8l3.5-1.5Z"
      fill="#F87"
    />
  </svg>
);

export default HeartIconLiked;
