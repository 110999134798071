import { useState, useEffect } from "react";
import useApplicationContext from "../../context/ApplicationContext";
import GreyTextIconBlock from "../blocks/GreyTextIconBlock";

const iconsMap = {
  General : "SvgBriefcase",
  "Food Service": "SvgForkKnife",
  Retail: "SvgRetail",
  Hospitality: "SvgHospitality",
  "General Labour": "SvgLabour",
  "Customer Service": "SvgCustomerService",
  "Sales Marketing": "SvgSaleMarketing",
  "Office Admin": "SvgOfficeAdmin",
  "Healthcare": "SvgHealthCare",
};

const JobTags = ({ data, maxShow, tagClassName = "", className = "" }) => {
  const { user } = useApplicationContext();
  const { jobType, city, postalCode, maxApplicantDistance, industries } = data || {};
  const [userLocation, setUserLocation] = useState(null);

  const distanceType = !!user?.countries?.filter((c) => c.key === "us")[0].value
    ? user.distance < 2
      ? "mile"
      : "miles"
    : "km"

  let tags = [];
  let remaining = [];

  city && tags.push({ text: city, iconName: "SvgLocationPin" });
  postalCode && tags.push({ text: postalCode, iconName: "SvgLocationPin" });
  jobType && tags.push({ text: jobType, iconName: "SvgJob" });
  maxApplicantDistance &&
    tags.push({ text: `${maxApplicantDistance}${distanceType}`, iconName: "SvgLocationPin" });
  industries &&
    industries.forEach((i) => {
      tags.push({ text: i, iconName: iconsMap[i] });
    });

  if (maxShow) {
    tags = tags.slice(0, maxShow);
    remaining = tags.slice(maxShow - 1).length;
  }

  useEffect(() => {
    if (user.countries && user.countries.length > 0) {
      if (user.countries[0].value === true) {
        setUserLocation("canada");
      } else if (user.countries[0].value === false) {
        setUserLocation("usa");
      } else {
        setUserLocation("canada");
      }
    }
  }, [user.countries]);

  return (
    <div className={`flex flex-wrap items-center -ml-1.5 ${className}`}>
      {!!tags?.length &&
        tags.map((tag, idx) => (
          <GreyTextIconBlock
            key={idx}
            className={tagClassName}
            text={tag.text}
            iconName={tag.iconName}
          />
        ))}
      {!!maxShow && remaining > 0 && <p className="mt-2 ml-1">+{remaining}</p>}
    </div>
  );
};

export default JobTags;
