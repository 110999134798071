import * as React from "react";

const SvgCheckCircle = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1471_5556)">
      <path
        d="M1 12C1 5.92501 5.92501 1 12 1C18.075 1 23 5.92501 23 12C23 18.075 18.075 23 12 23C5.92501 23 1 18.075 1 12Z"
        fill="white"
        stroke="currentColor"
        strokeWidth={props.strokeWidth || 2}
      />
      <path
        d="M16.4324 9.2002L10.7965 14.8002L7.97852 12.0002"
        stroke="currentColor"
        strokeWidth={props.strokeWidth || 2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1471_5556">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCheckCircle;
