import * as yup from "yup";

// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

const userSchema = (user) => {
    return yup.object().shape({
        fullName: yup
          .string()
          .nullable()
          .default(user?.fullName)
          .label("Full name")
          .required(),
        phoneNumber: yup
          .string()
          .nullable()
          .default(user?.phoneNumber)
          .label("Phone number")
          .required()
          .matches(phoneRegExp, "Phone number is not valid: 123-123-1234")
          .min(10, "Must be at least 9 digits")
          .max(12, "Must be under 11 digits"),
        email: yup
          .string()
          .nullable()
          .default(user?.email)
          .label("Email")
          .required()
          .email("Email is not valid."),
    });
}

const validateField = async (user, field, setFormErrors, formErrors) => {
    let obj = {};
    obj[field] = "";
    userSchema(user)
      .validateAt(field, user[field])
      .then(() => {
        // on success
        setFormErrors({ ...formErrors, ...obj });
      })
      .catch((err) => {
        // on error
        obj[field] = err.message;
        setFormErrors({ ...formErrors, ...obj });
      });
};

export {
    userSchema,
    validateField
}