
import React from 'react';
import ModalWrapper from './ModalWrapper';

const ResetIgnoredJobSuccessModal = (props) => {
  const { onCrossClick = () => {}} = props;

  return (
    <ModalWrapper {...props}>
      <div className="flex-col items-center justify-center w-full">
        <p className="px-12 text-xl text-center pb-7 font-semibold">
          Successfully Reset Ignored Jobs!
        </p>
        
        <button 
          className="text-base text-center text-white bg-primary-blue rounded py-3 w-full"
          onClick={(e) => {
            e.stopPropagation();
            window.dispatchEvent(new Event("should-get-jobs"));
            onCrossClick();
          }}
        >
          Close
        </button>
      </div>
    </ModalWrapper>
  )
}

export default ResetIgnoredJobSuccessModal;