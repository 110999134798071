import FileInput from "./FileInput";
import Icons from "../../icons";

const ResumePicker = ({
  resume,
  className = "",
  onPickDocument,
  onClearDocument = () => {},
  // onApplyClick = () => {},
  // showApplyButton = false,
}) => {
  return (
    <div className={`p-3 border border-gray-300 rounded ${className}`}>
      <div className="flex flex-row justify-between mb-4">
        <p className="text-base">Resume file</p>
        <p className="text-xs italic leading-6 text-gray-500">PDF file only</p>
      </div>
      {!!resume && (
        <div className="flex flex-row mb-4">
          <Icons.SvgPDF className="text-black" />
          <div className="pl-2 text-sm text-gray-700">
            <p>{resume?.name}</p>
            <p>{resume?.uploadedDate}</p>
          </div>
          <button className="ml-auto cursor-pointer">
            <Icons.SvgCrossMark
              className="w-5 h-5 text-black"
              onClick={(e) => {
                e.stopPropagation();
                onClearDocument();
              }}
            />
          </button>
        </div>
      )}
      <div className="flex flex-row">
        <FileInput resume={resume} onPickDocument={onPickDocument} />
        {/* {showApplyButton && !!resume && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onApplyClick();
            }}
            className="w-full px-2 py-3 ml-2 text-white rounded bg-primary-blue">
            Apply for job
          </button>
        )} */}
      </div>
    </div>
  );
};

export default ResumePicker;
