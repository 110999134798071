import * as React from "react"

const SvgLocationPin = (props) => (
  <svg
    width={17}
    height={17}
    fill="none"
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m8.333 15.278 3.82-3.82a5.402 5.402 0 1 0-7.64 0l3.82 3.82Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      stroke="currentColor"
      strokeWidth={3}
      strokeLinejoin="round"
      d="M8.333 7.639h.007v.007h-.007z"
    />
  </svg>
)

export default SvgLocationPin
