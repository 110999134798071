import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={56}
    height={56}
    fill="none"
    viewBox="0 0 56 56"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={56} height={56} rx={28} fill="#F87" />
    <path
      d="M41.467 24.133C41.333 19.6 37.733 16 33.2 16c-1.467 0-3.734 1.067-4.667 2.8-.133.4-.667.4-.8 0-1.067-1.6-3.2-2.667-4.8-2.667-4.4 0-8.133 3.6-8.267 8v.267c0 2.267.934 4.4 2.534 6v.133c.133.134 6.533 5.734 9.466 8.267.8.667 2 .667 2.8 0 2.934-2.533 9.2-8.133 9.467-8.267V30.4c1.6-1.467 2.534-3.6 2.534-6v-.267Z"
      fill="#fff"
    />
  </svg>
);

export default SvgComponent;
