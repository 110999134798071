import * as React from "react"

const SvgOfficeAdmin = (props) => (
  <svg
    width={25}
    height={26}
    fill="none"
    viewBox="0 0 25 26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      maskUnits="userSpaceOnUse"
      x={-0.693}
      y={0}
      width={26}
      height={26}
      fill="#000"
    >
      <path fill="#fff" d="M-.693 0h26v26h-26z" />
      <path d="M24.693 10.584v-.015a.598.598 0 0 0-.024-.128.528.528 0 0 0-.028-.068l-.003-.006a.466.466 0 0 0-.033-.056l-.01-.013a.484.484 0 0 0-.041-.051l-2.656-2.778-.034-.031a1.617 1.617 0 0 0-.038-.03l-.01-.007a.617.617 0 0 0-.039-.024l-.003-.002a.498.498 0 0 0-.038-.018l-.012-.005a.488.488 0 0 0-.032-.012l-.013-.005a.517.517 0 0 0-.042-.01l-.011-.002a.515.515 0 0 0-.035-.006c-.005 0-.01 0-.015-.002l-.038-.001h-1.606v-1.93a.914.914 0 0 0-.914-.914h-3.337V2.447a.704.704 0 0 0-.703-.703h-1.119v-.131A.614.614 0 0 0 13.245 1h-6.23a.614.614 0 0 0-.613.613v.13h-1.14a.704.704 0 0 0-.702.704V4.47H1.22a.914.914 0 0 0-.914.913v4.798c0 .357.207.666.506.816v8.15c0 .737.6 1.337 1.336 1.337h8.063v4.01c0 .28.226.506.505.506h13.469a.505.505 0 0 0 .505-.503l.003-.793V10.584h-.001Zm-2.655-1.506.968 1.012h-.968V9.077ZM7.413 2.01h5.436v.477H7.413v-.477Zm-1.844.743h.833v.13c0 .339.275.614.613.614h6.23c.339 0 .614-.275.614-.614v-.13h.811V4.47h-9.1V2.754ZM1.317 5.48h17.605v1.833h-8.206a.505.505 0 0 0-.505.505v1.185H6.8a.657.657 0 0 0-.656.656v.425H1.317V5.48Zm5.837 5.11v-.576h3.057v1.056H7.154v-.48ZM2.15 19.473a.326.326 0 0 1-.325-.328v-8.052h4.32v.33c0 .362.295.656.656.656h3.412v7.394H2.149Zm21.533 4.516h-12.46V8.324h9.806v2.27c0 .28.226.506.505.506l2.15.001V23.99Z" />
    </mask>
    <path
      d="M24.693 10.584v-.015a.598.598 0 0 0-.024-.128.528.528 0 0 0-.028-.068l-.003-.006a.466.466 0 0 0-.033-.056l-.01-.013a.484.484 0 0 0-.041-.051l-2.656-2.778-.034-.031a1.617 1.617 0 0 0-.038-.03l-.01-.007a.617.617 0 0 0-.039-.024l-.003-.002a.498.498 0 0 0-.038-.018l-.012-.005a.488.488 0 0 0-.032-.012l-.013-.005a.517.517 0 0 0-.042-.01l-.011-.002a.515.515 0 0 0-.035-.006c-.005 0-.01 0-.015-.002l-.038-.001h-1.606v-1.93a.914.914 0 0 0-.914-.914h-3.337V2.447a.704.704 0 0 0-.703-.703h-1.119v-.131A.614.614 0 0 0 13.245 1h-6.23a.614.614 0 0 0-.613.613v.13h-1.14a.704.704 0 0 0-.702.704V4.47H1.22a.914.914 0 0 0-.914.913v4.798c0 .357.207.666.506.816v8.15c0 .737.6 1.337 1.336 1.337h8.063v4.01c0 .28.226.506.505.506h13.469a.505.505 0 0 0 .505-.503l.003-.793V10.584h-.001Zm-2.655-1.506.968 1.012h-.968V9.077ZM7.413 2.01h5.436v.477H7.413v-.477Zm-1.844.743h.833v.13c0 .339.275.614.613.614h6.23c.339 0 .614-.275.614-.614v-.13h.811V4.47h-9.1V2.754ZM1.317 5.48h17.605v1.833h-8.206a.505.505 0 0 0-.505.505v1.185H6.8a.657.657 0 0 0-.656.656v.425H1.317V5.48Zm5.837 5.11v-.576h3.057v1.056H7.154v-.48ZM2.15 19.473a.326.326 0 0 1-.325-.328v-8.052h4.32v.33c0 .362.295.656.656.656h3.412v7.394H2.149Zm21.533 4.516h-12.46V8.324h9.806v2.27c0 .28.226.506.505.506l2.15.001V23.99Z"
      fill="currentColor"
    />
    <path
      d="M24.693 10.584v-.015a.598.598 0 0 0-.024-.128.528.528 0 0 0-.028-.068l-.003-.006a.466.466 0 0 0-.033-.056l-.01-.013a.484.484 0 0 0-.041-.051l-2.656-2.778-.034-.031a1.617 1.617 0 0 0-.038-.03l-.01-.007a.617.617 0 0 0-.039-.024l-.003-.002a.498.498 0 0 0-.038-.018l-.012-.005a.488.488 0 0 0-.032-.012l-.013-.005a.517.517 0 0 0-.042-.01l-.011-.002a.515.515 0 0 0-.035-.006c-.005 0-.01 0-.015-.002l-.038-.001h-1.606v-1.93a.914.914 0 0 0-.914-.914h-3.337V2.447a.704.704 0 0 0-.703-.703h-1.119v-.131A.614.614 0 0 0 13.245 1h-6.23a.614.614 0 0 0-.613.613v.13h-1.14a.704.704 0 0 0-.702.704V4.47H1.22a.914.914 0 0 0-.914.913v4.798c0 .357.207.666.506.816v8.15c0 .737.6 1.337 1.336 1.337h8.063v4.01c0 .28.226.506.505.506h13.469a.505.505 0 0 0 .505-.503l.003-.793V10.584h-.001Zm-2.655-1.506.968 1.012h-.968V9.077ZM7.413 2.01h5.436v.477H7.413v-.477Zm-1.844.743h.833v.13c0 .339.275.614.613.614h6.23c.339 0 .614-.275.614-.614v-.13h.811V4.47h-9.1V2.754ZM1.317 5.48h17.605v1.833h-8.206a.505.505 0 0 0-.505.505v1.185H6.8a.657.657 0 0 0-.656.656v.425H1.317V5.48Zm5.837 5.11v-.576h3.057v1.056H7.154v-.48ZM2.15 19.473a.326.326 0 0 1-.325-.328v-8.052h4.32v.33c0 .362.295.656.656.656h3.412v7.394H2.149Zm21.533 4.516h-12.46V8.324h9.806v2.27c0 .28.226.506.505.506l2.15.001V23.99Z"
      stroke="currentColor"
      strokeWidth={0.5}
      mask="url(#a)"
    />
    <path
      d="M15.575 10.846h-2.022a.63.63 0 0 0-.63.63v1.905c0 .348.281.63.63.63h2.022a.63.63 0 0 0 .63-.63v-1.905a.63.63 0 0 0-.63-.63Zm-.63 1.905h-.762v-.645h.762v.645ZM21.641 11.738h-4.304a.63.63 0 1 0 0 1.26h4.304a.63.63 0 0 0 0-1.26ZM15.7 14.903v-.013h-2.147a.63.63 0 0 0-.63.63v1.905c0 .348.281.63.63.63h2.022a.63.63 0 0 0 .63-.63v-1.904a.631.631 0 0 0-.505-.618Zm-.755 1.892h-.762v-.644h.762v.644ZM21.641 15.784h-4.304a.63.63 0 1 0 0 1.26h4.304a.63.63 0 1 0 0-1.26ZM15.575 19.189h-2.022a.63.63 0 0 0-.63.63v1.904c0 .348.281.63.63.63h2.022a.63.63 0 0 0 .63-.63V19.82a.63.63 0 0 0-.63-.63Zm-.63 1.904h-.762v-.644h.762v.644ZM21.641 20.082h-4.304a.63.63 0 1 0 0 1.26h4.304a.63.63 0 1 0 0-1.26Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.25}
    />
  </svg>
)

export default SvgOfficeAdmin
