import { useState } from "react";

import SvgNoJobsBanner from "../icons/SvgNoJobsBanner";
import UpdateJobLocationModal from "../common/modals/UpdateJobLocationModal";
import UpdateJobTypeModal from "../common/modals/UpdateJobTypeModal";

const NoJobsBanner = () => {
  const [showUpdateJobLocationModal, setShowUpdateJobLocationModal] = useState(false);
  const [showUpdateJobTypeModal, setShowUpdateJobTypeModal] = useState(false);

  return (
    <div className="flex flex-col items-center px-8 my-0 overflow-y-auto bg-gray-100 md:my-16">
      <SvgNoJobsBanner />
      <p className="max-w-md px-2 mt-0 text-2xl font-semibold text-center text-gray-900 md:mt-4">
        We are working on getting more jobs in your area. Stay tuned!
      </p>
      <p className="px-12 mt-4 text-sm text-center text-gray-600 md:mt-8">
        You can expand the search area in your settings.
      </p>
      <button
        className="mt-4 text-base font-semibold md:mt-8 text-dark-blue"
        onClick={() => setShowUpdateJobLocationModal(true)}>
        Location Settings...
      </button>
      <button
        className="w-full max-w-xs py-3 mt-4 text-lg text-center text-white rounded md:mt-8 bg-primary-blue"
        onClick={() => setShowUpdateJobTypeModal(true)}>
        Look for more jobs...
      </button>
      <UpdateJobLocationModal
        isVisible={showUpdateJobLocationModal}
        onCrossClick={() => setShowUpdateJobLocationModal(false)}
      />
      <UpdateJobTypeModal
        isVisible={showUpdateJobTypeModal}
        onCrossClick={() => setShowUpdateJobTypeModal(false)}
      />
    </div>
  );
};

export default NoJobsBanner;
