import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SwipeDirectionInfo from "../common/blocks/SwipeDirectionInfo";
import SwipeableCard from "../common/blocks/SwipeableCard";
import SwipeToast from "../common/blocks/SwipeToast";
import JobCardSavedOrApplied from "../common/jobs/JobCardSavedOrApplied";
import JobDetailModal from "../common/modals/JobDetailModal";
import JobExternalModal from "../common/modals/JobExternalModal";
import AddResumeModal from "../common/modals/AddResumeModal";
import Icons from "../icons";
import {
  storageKey,
  getStorageItem,
  setStorageItem,
  updateStorageItemWithArrayValue,
  removeItemFromStorageItemWithArrayValue,
  applyJob,
} from "../helpers/utils";

const SavedAndAppliedJobs = ({
  showCrossIcon = false,
  showBackIcon = false,
  onBackClick = () => {},
  onCrossClick = () => {},
}) => {
  const navigate = useNavigate();
  const [savedJobView, setSavedJobView] = useState(true);
  const [savedJobs, setSavedJob] = useState([]);
  const [appliedJobs, setAppliedJob] = useState([]);
  const [pressedItem, setPressedItem] = useState(null);
  const [swipeDir, setSwipeDir] = useState("--");
  const [swipedItem, setSwipedItem] = useState(null);
  // const [showJobDetailModal, setShowJobDetailModal] = useState(false);
  const [showExternalJobModal, setShowExternalJobModal] = useState(false);
  const [showAddResumeModal, setShowAddResumeModal] = useState(false);

  useEffect(() => {
    getSavedAndAppliedJobs();
  }, [savedJobView]);

  const getSavedAndAppliedJobs = () => {
    let _savedJobs = getStorageItem(storageKey.SAVED_JOBS);
    let _appliedJobs = getStorageItem(storageKey.APPLIED_JOBS);
    setSavedJob(_savedJobs);
    setAppliedJob(_appliedJobs?.filter((j) => !j.isCleared));
  };

  const handleClearAppliedJobs = () => {
    let _appliedJobs = getStorageItem(storageKey.APPLIED_JOBS);
    let updated = !!_appliedJobs?.length
      ? _appliedJobs.map((aj) => ({ ...aj, isCleared: true }))
      : [];
    setStorageItem(storageKey.APPLIED_JOBS, updated);
    setAppliedJob([]);
  };

  const handleCardOnClick = (item) => (e) => {
    navigate(`/jobs/${item.objectId}`);
    // setPressedItem(item);
    // setShowJobDetailModal(true);
  };

  const removeCard = (objectId) => {
    let remaining = savedJobs.filter((item) => item.objectId != objectId);
    setSavedJob(remaining);
  };

  const lastSwipedDirection = async (swipeDir, item) => {
    setSwipedItem(item);
    if (swipeDir == "right" && item.isExternalJob) {
      const vejm = getStorageItem(storageKey.VIEW_EXTERNAL_JOB_MODAL);
      if (vejm === false) {
        item?.jobUrl && window.open(item.jobUrl, "_blank");
      } else {
        setShowExternalJobModal(true);
      }
      removeCard(item?.objectId);
      return;
    }

    setSwipeDir(swipeDir);
    if (!item || swipeDir == "--") return;
    if (swipeDir == "left") {
      setTimeout(() => {
        updateStorageItemWithArrayValue(storageKey.IGNORED_JOBS, item);
        removeCard(item?.objectId);
      }, 50);
    }
    if (swipeDir == "right") {
      setSwipedItem(item);
      await handleApplyJob(item);
      removeItemFromStorageItemWithArrayValue(storageKey.SAVED_JOBS, item);
    }
  };

  const handleApplyJob = async (item) => {
    let res = await applyJob({ job: item });
    if (res.status == "OK") {
      setTimeout(() => {
        updateStorageItemWithArrayValue(storageKey.APPLIED_JOBS, {
          ...item,
          appliedDate: res.data?.appliedDate,
          applicationId: res.data?.applicationId,
        });
        removeCard(item?.objectId);
      }, 10);
    } else {
      setSwipedItem(null);
    }
  };

  return (
    <div className="flex flex-col max-h-screen overflow-hidden py-7">
      <div className="flex items-center px-4 mb-6 md:px-8">
        {showBackIcon && (
          <button className="mr-4 cursor-pointer">
            <Icons.SvgRightArrow
              className="w-6 h-6 rotate-180 text-icon-color"
              onClick={onBackClick}
            />
          </button>
        )}
        <p className="text-sm font-semibold text-gray-700 capitalize">
          {savedJobView ? "Saved" : "Applied"} Jobs
        </p>
        {showCrossIcon && (
          <button className="ml-auto cursor-pointer">
            <Icons.SvgCrossMark
              className="w-6 h-6 text-icon-color"
              onClick={onCrossClick}
            />
          </button>
        )}
      </div>
      <div className="grid grid-cols-2 border-gray-300 border-y">
        <button
          className={`text-sm text-center uppercase font-semibold py-3 ${
            savedJobView
              ? "bg-primary-blue text-white"
              : "bg-white text-light-grey-blue-01"
          }`}
          onClick={() => setSavedJobView(true)}>
          Saved
        </button>
        <button
          className={`text-sm text-center uppercase font-semibold py-3 ${
            !savedJobView
              ? "text-white bg-primary-blue"
              : "bg-white text-light-grey-blue-01"
          }`}
          onClick={() => setSavedJobView(false)}>
          Applied for
        </button>
      </div>
      <div className="px-4 overflow-y-scroll md:px-8 hide-scrollbar">
        {savedJobView && (
          <>
            <SwipeDirectionInfo className="pb-6 pt-7.5" />
            <p className="text-gray-600 text-sm mt-3.5 mb-5">
              {savedJobs?.length || 0} Jobs you saved
            </p>
          </>
        )}

        {!savedJobView && (
          <div className="flex justify-between px-4 py-4 text-sm text-gray-600">
            <p>{appliedJobs?.length || 0} Jobs Applied</p>
            <button className="font-bold bg-white" onClick={handleClearAppliedJobs}>
              Clear History
            </button>
          </div>
        )}
        {savedJobView
          ? !!savedJobs?.length &&
            savedJobs.map((item) => (
              <div key={item?.objectId}>
                <SwipeableCard
                  item={item}
                  swipedDirection={lastSwipedDirection}
                  setShowAddResumeModal={setShowAddResumeModal}
                  setSwipedItem={setSwipedItem}
                  onClick={handleCardOnClick(item)}>
                  <JobCardSavedOrApplied jobApplied={false} data={item} />
                </SwipeableCard>
                {swipedItem?.objectId == item?.objectId && swipeDir != "--" && (
                  <SwipeToast swipeDir={swipeDir} />
                )}
              </div>
            ))
          : !!appliedJobs?.length &&
            appliedJobs.map((item) => (
              <JobCardSavedOrApplied
                key={item.objectId}
                jobApplied={true}
                data={item}
                onClick={handleCardOnClick(item)}
              />
            ))}
      </div>

      {/* <JobDetailModal
        isVisible={showJobDetailModal}
        onCrossClick={() => {
          setShowJobDetailModal(false);
          setPressedItem(null);
          getSavedAndAppliedJobs();
        }}
        data={pressedItem}
      /> */}
      <JobExternalModal
        data={swipedItem}
        isVisible={showExternalJobModal}
        onCrossClick={() => setShowExternalJobModal(false)}
      />
      <AddResumeModal
        isVisible={showAddResumeModal}
        onCrossClick={() => setShowAddResumeModal(false)}
        onApplyClick={() => {
          handleApplyJob(swipedItem);
          setShowAddResumeModal(false);
        }}
      />
    </div>
  );
};

export default SavedAndAppliedJobs;
