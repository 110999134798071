import React from "react";
import Icons from "../../icons";

const GreyTextIconBlock = ({ text, iconName, className = "" }) => {
 
  const Icon = Icons[iconName];

  const iconWidth = 20; // Default width for all icons
  const iconHeight = (iconName === "SvgBriefcase" || iconName === "SvgForkKnife") ? 16 : 20; // Height 16 for SvgBriefcase and SvgForkKnife, otherwise 20

  return (
    <div
      className={`cursor-default flex items-center justify-center bg-gray-100 rounded px-2 py-1.5 mx-1.5 ${className}`}>
      {Icon && (
        <Icon
          className="mr-1 text-gray-500"
          width={iconWidth}
          height={iconHeight}
        />
      )}
      <p className="flex-1 text-sm whitespace-nowrap">{text}</p>
    </div>
  );
};

export default GreyTextIconBlock;
