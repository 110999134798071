import { useState, useEffect } from "react";
import JobIsExternal from "./JobIsExternal";
import JobTags from "./JobTags";
import JobLogoAndAddress from "./JobLogoAndAddress";
import Icons from "../../icons";
import {
  checkIfDateIsToday,
  getAmPmTime,
  getDateFormat,
  storageKey,
  getStorageItem,
} from "../../helpers/utils";

export default function JobCardSavedOrApplied(props) {
  const { jobApplied, data = {}, onClick = () => {} } = props;
  const [savedJob, setSavedJob] = useState(false);

  let appliedDate = "";
  let appliedTime = "";
  if (data.appliedDate && jobApplied) {
    appliedDate = new Date(data.appliedDate);
    appliedTime = getAmPmTime(appliedDate);
    if (checkIfDateIsToday(appliedDate)) {
      appliedDate = "Today";
    } else {
      appliedDate = getDateFormat(appliedDate);
    }
  }

  useEffect(() => {
    async function checkIfSavedJob(params) {
      let _savedJobs = await getStorageItem(storageKey.SAVED_JOBS);
      let isSaved = _savedJobs
        ? _savedJobs.findIndex((i) => i.objectId == data?.objectId) != -1
        : false;
      setSavedJob(isSaved);
    }
    checkIfSavedJob();
  }, []);

  return (
    <div
      className="p-3 mb-4 bg-white border border-gray-300 rounded-lg cursor-pointer"
      onClick={onClick}>
      <div className="flex justify-between">
        <p className="mb-4 text-base font-bold text-black">{data?.title}</p>
        {savedJob ? <Icons.SvgHeartLiked /> : null}
      </div>

      <JobLogoAndAddress {...data} logoClassName="w-10 h-10" />
      {data?.isExternalJob && <JobIsExternal />}

      {!jobApplied && <JobTags data={data} tagClassName="mt-2" />}

      {jobApplied && (
        <p className="w-full p-2 mt-2 text-center bg-gray-100">
          Applied {appliedDate}, {appliedTime}
        </p>
      )}
    </div>
  );
}
