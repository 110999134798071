import React, { forwardRef } from "react";

const BaseInput = forwardRef((props, ref) => {
  const {
    value = "",
    type = "text",
    showLabel = false,
    label = "",
    name = "",
    className = "",
    inputClassName = "",
    subLabelClassName = "",
    onChange = () => {},
    errormessage,
  } = props;
  return (
    <div className={`mb-4 ${className} input-container`}>
      <label htmlFor={props.id} className={`${showLabel ? "" : "hidden"}`}>
        {label || name}
      </label>
      {/* For Accessibility */}
      <div className="relative">
        <input
          {...props}
          ref={ref}
          className={`relative w-full h-[54px] p-2 border rounded focus:border-primary-blue bg-transparent ${
            !!errormessage
              ? "border-red"
              : "border-gray-300 valid:border-none valid:bg-light-grey-blue-03"
          } ${inputClassName}`}
          type={type}
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
        />
        <label
          className={`absolute left-0 pl-2 -z-10 text-gray-700 ${
            (value && "filled") || "text-secondary-800"
          } ${subLabelClassName}`}
          htmlFor={props.name}>
          {props.label}
        </label>
      </div>
      {!!errormessage && (
        <span className="my-1 text-xs text-red">
          {!value ? "This field is required" : errormessage}
        </span>
      )}
    </div>
  );
});

export default BaseInput;
