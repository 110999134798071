import React from 'react';

const HtmlRenderer = ({ html, className = ""}) => {
  return (
    <div
      dangerouslySetInnerHTML={{__html: html?.replace(/\n/g,"<br />")}}
      className={`${className}`}
    />
  );
}

export default HtmlRenderer;