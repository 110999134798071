import * as React from "react";

const SvgCardView = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <mask id="path-1-inside-1_1499_4844" fill="white">
      <rect width="16" height="16" rx="1" />
    </mask>
    <rect
      width="16"
      height="16"
      rx="1"
      stroke="currentColor"
      strokeWidth="3"
      mask="url(#path-1-inside-1_1499_4844)"
    />
  </svg>
);

export default SvgCardView;
