import * as React from "react";

const SvgLabour = (props) => (
  <svg
    width={24}
    height={23}
    fill="none"
    viewBox="4 1 24 27"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect
      x={9}
      y={8}
      width={14}
      height={19}
      rx={7}
      stroke="currentColor"
      strokeWidth={2}
    />
    <path
      d="M7 12a9 9 0 1 1 18 0v1H7v-1Z"
      fill="#fff"
      stroke="currentColor"
      strokeWidth={2}
    />
    <path
      d="M22.75 15.75h3.5v2.75a1.75 1.75 0 1 1-3.5 0v-2.75ZM5.75 15.75h3.5v2.75a1.75 1.75 0 1 1-3.5 0v-2.75Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <rect
      x={5}
      y={12}
      width={22}
      height={4}
      rx={2}
      fill="#fff"
      stroke="currentColor"
      strokeWidth={2}
    />
    <mask id="a" fill="#fff">
      <rect x={13} y={3} width={6} height={7} rx={1} />
    </mask>
    <rect
      x={13}
      y={3}
      width={6}
      height={7}
      rx={1}
      stroke="currentColor"
      strokeWidth={4}
      mask="url(#a)"
    />
  </svg>
);

export default SvgLabour;
