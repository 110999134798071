import * as React from "react";

const SvgLocationPick = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5 7L5.25201 9.97523C3.65873 10.7339 3.75729 13.034 5.40963 13.6536L8.14879 14.6808C8.68964 14.8836 9.11639 15.3104 9.3192 15.8512L10.3464 18.5904C10.966 20.2427 13.2661 20.3413 14.0248 18.748L17 12.5"
      stroke="#292929"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 7L16.9111 4.42326C18.6115 3.61355 20.3864 5.38846 19.5767 7.08885L17 12.5"
      stroke="#1C7FB9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgLocationPick;
