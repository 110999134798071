import * as React from "react";

const SvgCheckCircleEx = (props) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_357_16777)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 0C12.5364 0 0 12.5364 0 28C0 43.4636 12.5364 56 28 56C43.4636 56 56 43.4636 56 28C56 12.5364 43.4636 0 28 0Z"
        fill="url(#paint0_linear_357_16777)"
      />
      <path
        d="M38.3409 21.4668L25.1904 34.5335L18.6152 28.0001"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_357_16777"
        x1="11.6513"
        y1="-178.231"
        x2="91.2411"
        y2="-170.503"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1C7FB9" />
        <stop offset="0.893229" stopColor="#95CBEE" />
        <stop offset="1" stopColor="#95CBEE" />
      </linearGradient>
      <clipPath id="clip0_357_16777">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCheckCircleEx;
