import { useState } from "react";

const Tooltip = (props) => {
  const { value = "" } = props;

  const [displayTooltip, setDisplayTooltip] = useState(false);

  return (
    <div
      className="absolute top-0 left-0 flex items-center justify-center w-full h-full"
      onMouseEnter={() => setDisplayTooltip(true)}
      onMouseLeave={() => setDisplayTooltip(false)}>
      <div
        className={`left-1/4 -translate-x-1/2 top-8 absolute px-3 cursor-default rounded-2xl bg-light-grey-blue-01 text-white font-semibold text-xs overflow ${
          displayTooltip ? "block" : "hidden"
        }`}>
        <span>{value}</span>
      </div>
    </div>
  );
};

export default Tooltip;
