import * as React from "react"

const SvgCustomerService = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={5}
      y={4}
      width={14}
      height={19}
      rx={7}
      stroke="currentColor"
      strokeWidth={2}
    />
    <path d="M19 12h2a2 2 0 1 1 0 4h-2v-4Z" stroke="currentColor" strokeWidth={2} />
    <rect
      x={14}
      y={17}
      width={3}
      height={4}
      rx={1}
      transform="rotate(90 14 17)"
      stroke="currentColor"
      strokeWidth={2}
    />
    <path d="M5 16H3a2 2 0 1 1 0-4h2v4Z" stroke="currentColor" strokeWidth={2} />
    <path
      d="M5 10.5c1.5.167 3.7-.9 4.5-2.5 1 1.5 4.1 4 8.5 2"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M2 12v-1C2 5.477 6.477 1 12 1v0c5.523 0 10 4.477 10 10v1M22 16v1a2 2 0 0 1-2 2h-5"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgCustomerService
