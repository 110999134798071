import * as React from "react"

const SvgCar = (props) => (
  <svg
    width={24}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.167 19.333H3.833V20.5a1.167 1.167 0 0 1-1.166 1.167H1.5A1.167 1.167 0 0 1 .333 20.5V8.833l2.894-6.752A2.333 2.333 0 0 1 5.373.667h13.254A2.334 2.334 0 0 1 20.77 2.08l2.896 6.752V20.5a1.167 1.167 0 0 1-1.167 1.167h-1.167a1.166 1.166 0 0 1-1.166-1.167v-1.167Zm1.166-8.166H2.667V17h18.666v-5.833ZM2.872 8.833h18.256L18.628 3H5.373l-2.5 5.833h-.001Zm2.711 7a1.75 1.75 0 1 1 0-3.5 1.75 1.75 0 0 1 0 3.5Zm12.834 0a1.75 1.75 0 1 1 0-3.5 1.75 1.75 0 0 1 0 3.5Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgCar
